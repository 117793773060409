// ##############################################
// NAVEGACIÓN DE NOODLES
// ##############################################
.toggle-list {
  > div:not(.active) {
    display: none;
  }
  > .active {
    display: flex;
    flex-direction: column;
  }
}
.nav__noodles {
  margin-top: 80px;
  background-color: #eee;
  .content__nav__noodles {
    width: 95%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left__side {
      a {
        margin: 0;
        color: #84084c;
        font-size: 20px;
        font-family: 'AmsiProCond-Black';
        text-transform: uppercase;
        letter-spacing: 1px;
        img {
          margin-right: 10px;
        }
        span {
          color: $rojoBase;
        }
      }
    }
    .right__side {
      position: relative;
      #option__mobile__explosivos {
        width: 100%;
        padding: 20px 10px 10px 10px;
        display: none;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #ac2227;
        button {
          width: 100%;
          border: none;
          margin: 0;
          color: #fff;
          font-size: 20px;
          font-family: 'AmsiProCond-Black';
          text-transform: uppercase;
          letter-spacing: 1px;
          outline: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          .icon__nav {
            height: 20px;
            min-width: 20px;
            margin-left: 5px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../images/products/noodles/i-intensos-white.svg');
          }
        }
        img {
          width: 60px;
          &.arrow {
            width: 20px;
            cursor: pointer;
            position: absolute;
            right: 2%;
            top: 50%;
            transform: translateY(-50%);
            transition-duration: 0.5s;
            transition-property: transform;
          }
        }
        &.active img.arrow {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      --c-indicator-active-bg: #ac2227;
      --c-indicator-active-text: #fff;
      --c-indicator-no-active-bg: #eee;
      --c-indicator-no-active-text: #ac2227;
      ul.product-category-submenu {
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none;
        li {
          width: auto;
          padding: 20px 10px 10px 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background: var(--c-indicator-no-active-bg);
          button {
            width: 100%;
            border: none;
            margin: 0;
            color: var(--c-indicator-no-active-text);
            font-size: 20px;
            font-family: 'AmsiProCond-Black';
            text-transform: uppercase;
            letter-spacing: 1px;
            outline: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            .icon__nav {
              height: 20px;
              min-width: 20px;
              margin-left: 5px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
          img {
            width: 60px;
            opacity: 0;
          }
          &.active,
          &:hover {
            background-color: var(--c-indicator-active-bg);
            button {
              color: var(--c-indicator-active-text);
            }
            img {
              opacity: 1;
            }
          }
        }
        &.noodles-list {
          li {
            &:nth-child(1) {
              button .icon__nav {
                background-image: url('../images/products/noodles/i-intensos-red.svg');
              }
              &.active,
              &:hover {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-intensos-white.svg');
                }
              }
            }
            &:nth-child(2) {
              button .icon__nav {
                background-image: url('../images/products/noodles/i-camaron-red.svg');
              }
              &.active,
              &:hover {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-camaron-white.svg');
                }
              }
            }
            &:nth-child(3) {
              button .icon__nav {
                background-image: url('../images/products/noodles/i-pollo-red.svg');
              }
              &.active,
              &:hover {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-pollo-white.svg');
                }
              }
            }
            &:nth-child(4) {
              button .icon__nav {
                background-image: url('../images/products/noodles/i-res-red.svg');
              }
              &.active,
              &:hover {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-res-white.svg');
                }
              }
            }
            &:nth-child(5) {
              button .icon__nav {
                background-image: url('../images/products/noodles/i-ramen-red.svg');
              }
              &.active,
              &:hover {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-ramen-white.svg');
                }
              }
            }
          }
        }
        &.ramen-list {
          li {
            &:nth-child(1) {
              button .icon__nav {
                background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/menu-icons/i-ramen-red.svg');
              }
              &.active,
              &:hover {
                button .icon__nav {
                  background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/menu-icons/i-ramen-white.svg');
                }
              }
            }
            &:nth-child(2) {
              button .icon__nav {
                background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/menu-icons/i-asian-red.svg');
              }
              &.active,
              &:hover {
                button .icon__nav {
                  background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/menu-icons/i-asian-white.webp');
                }
              }
            }
          }
        }

        &.inverted {
          --c-indicator-active-bg: white;
          --c-indicator-active-text: #ac2227;
          --c-indicator-no-active-bg: #ac2227;
          --c-indicator-no-active-text: #fff;
          &.noodles-list {
            li {
              &:nth-child(1) {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-intensos-white.svg');
                }
                &.active,
                &:hover {
                  button .icon__nav {
                    background-image: url('../images/products/noodles/i-intensos-red.svg');
                  }
                }
              }
              &:nth-child(2) {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-camaron-white.svg');
                }
                &.active,
                &:hover {
                  button .icon__nav {
                    background-image: url('../images/products/noodles/i-camaron-red.svg');
                  }
                }
              }
              &:nth-child(3) {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-pollo-white.svg');
                }
                &.active,
                &:hover {
                  button .icon__nav {
                    background-image: url('../images/products/noodles/i-pollo-red.svg');
                  }
                }
              }
              &:nth-child(4) {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-res-white.svg');
                }
                &.active,
                &:hover {
                  button .icon__nav {
                    background-image: url('../images/products/noodles/i-res-red.svg');
                  }
                }
              }
              &:nth-child(5) {
                button .icon__nav {
                  background-image: url('../images/products/noodles/i-ramen-white.svg');
                }
                &.active,
                &:hover {
                  button .icon__nav {
                    background-image: url('../images/products/noodles/i-ramen-red.svg');
                  }
                }
              }
            }
          }
          &.ramen-list {
            li {
              &:nth-child(1) {
                button .icon__nav {
                  background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/menu-icons/i-ramen-white.svg');
                }
                &.active,
                &:hover {
                  button .icon__nav {
                    background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/menu-icons/i-ramen-red.svg');
                  }
                }
              }
              &:nth-child(2) {
                button .icon__nav {
                  background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/menu-icons/i-asian-white.svg');
                }
                &.active,
                &:hover {
                  button .icon__nav {
                    background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/menu-icons/i-asian-red.webp');
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .nav__noodles .content__nav__noodles .left__side a {
    font-size: 17px;
  }
  .right__side ul li button {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .slide .content__slide {
    .left__side {
      flex: 1;
    }
    .right__side {
      flex: 1;
    }
  }
  .nav__noodles .content__nav__noodles .right__side ul {
    display: flex !important;
  }
}
@media (max-width: 991px) {
  .nav__noodles .content__nav__noodles {
    width: 100%;
    display: flex;
    flex-direction: column;
    .left__side {
      width: 100%;
      padding: 10px 0;
      justify-content: center;
      p {
        font-size: 16px;
        img {
          position: relative;
          top: 3px;
        }
      }
    }
    .right__side {
      width: 100%;
      #option__mobile__explosivos {
        display: flex;
      }
      ul {
        width: 100%;
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 5;
        background-color: #fff;
        li {
          width: 100%;
          border-bottom: 1px solid rgba(#da282f, 0.23);
          img {
            display: none;
          }
          &.active {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 552px) {
  .nav__noodles {
    margin-top: 60px;
    .content__nav__noodles {
      .left__side p {
        font-size: 14px;
      }
      .right__side {
        #option__mobile__explosivos {
          padding: 15px 7px 7px 7px;
          button {
            font-size: 16px;
          }
          img {
            width: 40px;
          }
        }
        ul li {
          padding: 15px 7px 7px 7px;
          button {
            font-size: 16px;
          }
        }
      }
    }
  }
}
// ##############################################
// SLIDE GRAL
// ##############################################
.left__slide__noodles,
.right__slide__noodles {
  width: 60px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 2%;
  z-index: 5;
  transform: translateY(-50%);
}
.right__slide__noodles {
  left: inherit;
  right: 2%;
}

@media (max-width: 991px) {
  .left__slide__noodles,
  .right__slide__noodles {
    width: 50px;
    top: inherit;
    bottom: 1%;
    transform: translateY(0%);
  }
}

@media (max-width: 552px) {
  .left__slide__noodles,
  .right__slide__noodles {
    width: 40px;
    top: inherit;
    bottom: 2%;
    transform: translateY(0%);
  }
}

.slider__productos.owl-carousel .owl-stage {
  display: flex;
  align-items: stretch;
}
.slide {
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  display: flex;
  // min-height: 35vw;
  position: relative;
  background-size: cover;
  background-position: center;

  height: calc(100vh - 150px);
  height: calc(100svh - 150px);
  min-height: 540px;

  .content__nutrimental {
    width: 100%;
    max-width: 50rem;
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    transition-duration: 0.5s;
    transition-property: opacity;
    transform: translate(-50%, -50%);
    .content__table {
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      .icon__close__nutrimental {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        position: absolute;
        top: 13.5%;
        right: 8%;
        transition-duration: 0.2s;
        transition-property: transform;
        &::before,
        &::after {
          content: '';
          width: 1.5rem;
          height: 1px;
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: #da282f;
        }
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
        @media (hover: hover) {
          &:hover {
            transform: rotate(90deg) scale(1.1);
          }
        }
      }
    }
    &.active {
      opacity: 1;
      pointer-events: inherit;
    }
  }
  .content__slide {
    padding: 0;
    .left__side img {
      height: auto;
    }
    .right__side {
      padding-left: 2%;
      flex-direction: column;
      align-items: flex-start;
      h2 {
        font-size: 3.6rem;
        font-family: 'AmsiProCond-Black';
        line-height: 1;
        text-transform: uppercase;
        .noodle-small {
          display: inline-flex;
          align-items: center;
          &::after {
            width: 6.25rem;
            content: '';
            height: 1rem;
            max-width: 100%;
            margin-left: 0.625rem;
            display: block;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../images/assets/noodle-horizontal-chico.svg);
          }
        }
      }
      .iconos__detalles {
        margin: 1.875rem 0;
        display: flex;
        .detalle {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin-right: 50px;
          img {
            width: 4.375rem;
            height: auto;
          }
          p {
            color: #fff;
            font-family: 'AmsiProCond-Black';
            text-align: center;
            text-transform: uppercase;
          }
          &:last-child {
            margin-right: 0;
          }
          &.tabla__nutrimental {
            cursor: pointer;
          }
        }
      }
      .descripcion__producto {
        h3 {
          margin: 0 0 15px;
          color: #f1fc46;
          font-size: 22px;
          font-family: 'AmsiProCond-Black';
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        p {
          margin: 0;
          color: #fff;
          font-size: 16px;
          line-height: 1.4;
        }
        &.descripcion__producto.color-black {
          h3 {
            color: #000;
          }
          p {
            color: #000;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .mobile-color-black {
    color: #000 !important;
  }
  .slide {
    background-position: top;
    .content__slide {
      flex-direction: column;
      justify-content: center;
      padding: 0;
      width: calc(100% - 2rem);
      .left__side img {
        width: auto;
        max-width: 80%;
        max-height: 26rem;
      }
      .right__side {
        padding-left: 0;
        margin-top: 1.875rem;
        h2 {
          font-size: 2rem;
          .noodle-small {
            &::after {
              width: 3.75rem;
            }
            &.mobile-xs {
              &::after {
                width: 1.25rem;
                background-size: auto 0.4rem;
              }
            }
          }
        }
        h3 {
          font-size: 1.25rem;
        }
        p {
          font-size: 0.95rem;
        }
        .iconos__detalles {
          margin: 10px 0;
          .detalle {
            margin-right: 2rem;
            img {
              width: 40px;
            }
            p {
              margin: 0;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
  .slider__productos .owl-nav {
    bottom: 66px;
    height: 2rem;
    width: calc(100% - 2rem);
    [class*='owl-'] {
      margin: 0;
      width: 2rem;
      height: 2rem;
    }
  }
}

@media (min-width: 992px) {
  .slide {
    .content__slide {
      width: calc(100% - 14rem);
      height: 100%;
      .left__side {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          aspect-ratio: 1;
          max-width: initial;
        }
      }
    }
  }
  .slide .content__slide .right__side .descripcion__producto {
    padding-right: 6%;
  }
}
@media (min-width: 1100px) {
  .slide .content__slide .right__side .descripcion__producto {
    h3 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
}
@media (min-width: 1200px) {
  .slide .content__slide .right__side .descripcion__producto {
    h3 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
}
// Noodles Specific rules
@media (max-width: 991px) {
  .noodles .slide,
  .camaron .slide,
  .pollo .slide,
  .res .slide {
    padding: 0.5rem 0 4.5rem 0;
  }
  .noodles .slide,
  .camaron .slide,
  .pollo .slide {
    height: calc(100vh - 224px);
    height: calc(100svh - 224px);
    min-height: 680px;
  }
  .res .slide {
    height: calc(100vh - 154px);
    height: calc(100svh - 154px);
    min-height: 680px;
  }
  .content__noodles {
    .slider__productos .owl-nav {
      bottom: 80px;
    }
  }
}

@media (min-width: 992px) {
  .noodles .slide,
  .camaron .slide,
  .pollo .slide {
    height: calc(100vh - 216px);
    height: calc(100svh - 216px);
  }
  .res .slide {
    height: calc(100vh - 146px);
    height: calc(100svh - 146px);
  }
}
// ##############################################
// INTENSOS
// ##############################################
.noodles__intensos {
  --c-bar-esquites: #a6e302;
  --c-bar-bbq: #924410;
  --c-bar-dark-dragon: #2b2b2b;
  --c-bar-camaron-fuego: #630032;
  --c-bar-diablo: #810907;
  --c-bar-flamas: #694696;

  .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/intensos/bg-ESQUITES.jpg');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/intensos/bg-FLAMAS.jpg');
    }
    &.slide__3 {
      background-image: url('../images/products/noodles/explosivos/bg-morado.jpg');
    }
    &.slide__4 {
      background-image: url('../images/products/noodles/explosivos/bg-roast-bbq.jpg');
    }
    &.slide__5 {
      background-image: url('../images/products/noodles/explosivos/bg-dark-dragon.png');
    }
    &.slide__6 {
      background-image: url('../images/products/noodles/explosivos/bg-diablo.jpg');
    }
  }
  .owl-dots {
    background-color: var(--c-bar-flamas);
    &[data-id='0'] {
      background-color: var(--c-bar-flamas);
    }
    &[data-id='1'] {
      background-color: var(--c-bar-diablo);
    }
    &[data-id='2'] {
      background-color: var(--c-bar-camaron-fuego);
    }
    &[data-id='3'] {
      background-color: var(--c-bar-dark-dragon);
    }
    &[data-id='4'] {
      background-color: var(--c-bar-esquites);
    }
    .owl-dot {
      &:nth-child(1) span {
        //2
        &::after {
          background-color: var(--c-bar-flamas);
        }
        &::before {
          content: 'Flamas limón';
        }
      }
      &:nth-child(2) span {
        //6
        &::after {
          background-color: var(--c-bar-diablo);
        }
        &::before {
          content: 'diablo';
        }
      }
      &:nth-child(3) span {
        &::after {
          background-color: var(--c-bar-camaron-fuego);
        }
        &::before {
          content: 'camarón fuego';
        }
      }
      &:nth-child(4) span {
        //5
        &::after {
          background-color: var(--c-bar-dark-dragon);
        }
        &::before {
          content: 'dark dragon';
        }
      }
      &:nth-child(5) span {
        &::after {
          background-color: var(--c-bar-esquites);
        }
        &::before {
          content: 'Esquites';
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .noodles__intensos .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/intensos/bg-ESQUITES-mob.jpg');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/intensos/bg-flamas-mob.jpg');
    }
    &.slide__3 {
      background-image: url('../images/products/noodles/explosivos/bg-morado-mobile.jpg');
    }
    &.slide__4 {
      background-image: url('../images/products/noodles/explosivos/bg-bbq-mobile.jpg');
    }
    &.slide__5 {
      background-image: url('../images/products/noodles/explosivos/bg-dark-mobile.jpg');
    }
    &.slide__6 {
      background-image: url('../images/products/noodles/explosivos/bg-diablo-mobile.jpg');
    }
  }
}
// ##############################################
// CAMARON
// ##############################################

.noodles__camaron {
  --c-bar-camaron: #d07585;
  --c-bar-picante: #ae035c;
  --c-bar-hot-sauce: #768726;
  --c-bar-chabanero: #3f2b2e;
  .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/camaron/bg-camaron.png');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/camaron/bg-camaron-picante.png');
    }
    &.slide__3 {
      background-image: url('../images/products/noodles/explosivos/bg-dark-dragon.png');
    }
    &.slide__4 {
      background-image: url('../images/products/noodles/camaron/bg-camaron-hotsauce.png');
    }
  }
  .owl-dots {
    &[data-id='0'] {
      background-color: var(--c-bar-camaron);
    }
    &[data-id='1'] {
      background-color: var(--c-bar-picante);
    }
    &[data-id='2'] {
      background-color: var(--c-bar-hot-sauce);
    }
    &[data-id='3'] {
      background-color: var(--c-bar-chabanero);
    }
    .owl-dot {
      &:nth-child(1) span {
        &::after {
          background-color: var(--c-bar-camaron);
        }
        &::before {
          content: 'camarón';
        }
      }
      &:nth-child(2) span {
        &::after {
          background-color: var(--c-bar-picante);
        }
        &::before {
          content: 'picante';
        }
      }
      &:nth-child(3) span {
        &::after {
          background-color: var(--c-bar-hot-sauce);
        }
        &::before {
          content: 'hot sauce';
        }
      }
      &:nth-child(4) span {
        &::after {
          background-color: var(--c-bar-chabanero);
        }
        &::before {
          content: 'habanero';
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .noodles__camaron .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/camaron/bg-camaron-mobile.jpg');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/explosivos/bg-morado-mobile.jpg');
    }
    &.slide__3 {
      background-image: url('../images/products/noodles/explosivos/bg-dark-mobile.jpg');
    }
    &.slide__4 {
      background-image: url('../images/products/noodles/camaron/bg-hot-sauce-mobile.jpg');
    }
  }
}
// ##############################################
// POLLO
// ##############################################

.noodles__pollo {
  .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/pollo/bg-pollo.png');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/explosivos/bg-dark-dragon.png');
    }
    &.slide__3 {
      background-image: url('../images/products/noodles/camaron/bg-camaron-hotsauce.png');
    }
  }
  .owl-dots {
    &[data-id='0'] {
      background-color: #c78c13;
    }
    &[data-id='1'] {
      background-color: #768726;
    }
    &[data-id='2'] {
      background-color: #3f2b2e;
    }
    .owl-dot {
      &:nth-child(1) span {
        &::after {
          background-color: #c78c13;
        }
        &::before {
          content: 'Pollo';
        }
      }
      &:nth-child(2) span {
        &::after {
          background-color: #768726;
        }
        &::before {
          content: 'Hot Sauce';
        }
      }
      &:nth-child(3) span {
        &::after {
          background-color: #3f2b2e;
        }
        &::before {
          content: 'Habanero';
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .noodles__pollo .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/pollo/bg-pollo-mobile.jpg');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/explosivos/bg-dark-mobile.jpg');
    }
    &.slide__3 {
      background-image: url('../images/products/noodles/camaron/bg-hot-sauce-mobile.jpg');
    }
  }
}
// ##############################################
// RES
// ##############################################

.noodles__res {
  .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/res/bg-res.png');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/camaron/bg-camaron-hotsauce.png');
    }
  }
  .owl-dots {
    &[data-id='0'] {
      background-color: #810907;
    }
    &[data-id='1'] {
      background-color: #768726;
    }
    .owl-dot {
      &:nth-child(1) span {
        &::after {
          background-color: #810907;
        }
        &::before {
          content: 'Res';
        }
      }
      &:nth-child(2) span {
        &::after {
          background-color: #768726;
        }
        &::before {
          content: 'Hot sauce';
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .noodles__res .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/explosivos/bg-diablo-mobile.jpg');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/camaron/bg-hot-sauce-mobile.jpg');
    }
  }
}
// ##############################################
// MARISCOS
// ##############################################

.noodles__mariscos {
  .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/mariscos/bg-mariscos.png');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/explosivos/bg-dark-dragon.png');
    }
  }
  .owl-dots {
    &[data-id='0'] {
      background-color: #5aafca;
    }
    &[data-id='1'] {
      background-color: #2b2b2b;
    }
    .owl-dot {
      &:nth-child(1) span {
        &::after {
          background-color: #5aafca;
        }
        &::before {
          content: 'Mariscos';
        }
      }
      &:nth-child(2) span {
        &::after {
          background-color: #2b2b2b;
        }
        &::before {
          content: 'Habanero';
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .noodles__mariscos .slide {
    &.slide__1 {
      background-image: url('../images/products/noodles/mariscos/bg-mariscos-mobile.jpg ');
    }
    &.slide__2 {
      background-image: url('../images/products/noodles/explosivos/bg-dark-mobile.jpg');
    }
  }
}
// ##############################################
// MODO DE PREPARACIÓN
// ##############################################
.modo__preparacion__noodles {
  padding-top: 50px;
  background-size: contain;
  background-image: url('../images/home/nissin-cupnoodles-pattern.png');
  .ufo__mp {
    background-image: url('../images/home/nissin-ufo-pattern.png');
  }
  .ramen__mp {
    background-image: url('../images/home/nissin-topramen-pattern.png');
  }
  .maxisopa__mp {
    background-image: url('../images/home/nissin-maxisopa-pattern.png');
  }
  .content__title__nissin {
    padding: 10px 0;
    text-align: center;
    h2 {
      margin: 0;
      color: rgba($color-negro, 0.7);
      font-size: 80px;
      font-family: 'CoreSansMSC87CnHeavy';
      text-transform: uppercase;
    }
    p {
      margin: 0;
      color: rgba($color-negro, 0.7);
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .pasos {
    align-items: flex-start;
    padding: 50px 0 200px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    #paso1,
    #paso2,
    #paso3,
    #paso1__ramen,
    #paso2__ramen,
    #paso3__ramen,
    #paso1__asian,
    #paso2__asian,
    #paso3__asian {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 0;
      width: 320px;
      .indicacion {
        width: 100%;
        text-align: center;
        position: relative;
        margin: 2rem 0 0;
        p {
          color: #464646;
          font-size: 22px;
          font-family: 'CoreSansMSC87CnHeavy';
          letter-spacing: 0.5px;
          line-height: 1.2;
          margin: 0;
          span:not(.measurement) {
            color: #da282f;
          }
          span.measurement {
            font-family: 'Quicksand', sans-serif;
            font-optical-sizing: auto;
            font-weight: 700;
            font-style: normal;
          }
        }
      }
      svg {
        max-width: 320px;
      }
    }
    #paso1 {
      svg {
        margin-left: 10%;
      }
    }
    #paso2,
    #paso3 {
      svg {
        margin-left: 11%;
      }
    }
  }
  .video-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 1rem;
    .videoWrapper {
      position: relative;
      width: calc(100% - 2rem);
      max-width: 960px;
      margin: 0 auto 50px;
    }
    .videoArea {
      position: relative;
      padding: 0 0 56.52%;
      height: 0;
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

@media (max-width: 991px) {
  .modo__preparacion__noodles {
    .content__title__nissin {
      padding: 10px 0;
      text-align: center;
      h2 {
        font-size: 60px;
      }
      p {
        font-size: 20px;
      }
    }
    .pasos {
      align-items: center;
      flex-direction: column;
      padding: 10px 0 100px;
      #paso1,
      #paso2,
      #paso3,
      #paso1__ramen,
      #paso2__ramen,
      #paso3__ramen {
        max-width: 250px;
        margin-bottom: 60px;
        .indicacion {
          right: 0px;
          p {
            font-size: 16px;
          }
        }
      }
      #paso1,
      #paso1__ramen {
        margin-bottom: 90px;
      }
      #paso2,
      #paso2__ramen {
        .indicacion {
          right: 0px;
        }
      }
      #paso1,
      #paso2,
      #paso3 {
        svg {
          margin-left: 12%;
        }
      }
    }
  }
}
@media (max-width: 552px) {
  .modo__preparacion__noodles {
    .pasos {
      #paso1,
      #paso2,
      #paso3,
      #paso1__ramen,
      #paso2__ramen,
      #paso3__ramen {
        max-width: 200px;
        margin-bottom: 40px;
        .indicacion {
          right: 0px;
          p {
            font-size: 16px;
          }
        }
      }
      #paso1__ramen,
      #paso2__ramen,
      #paso3__ramen {
        margin-bottom: 100px;
        .indicacion {
          bottom: -50%;
        }
      }
      #paso1 {
        margin-bottom: 70px;
      }
      #paso2,
      #paso2__ramen {
        .indicacion {
          right: 0px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .noodles__camaron .slide {
    background-image: url('../images/products/noodles/camaron/bg-camaron-mobile.jpg');
  }
  .noodles__pollo .slide {
    background-image: url('../images/products/noodles/pollo/bg-pollo-mobile.jpg');
  }
  .noodles__res .slide {
    background-image: url('../images/products/noodles/res/bg-res-mobile.jpg');
  }
  .noodles__mariscos .slide {
    background-image: url('../images/products/noodles/mariscos/bg-mariscos-mobile.jpg');
  }
}

.product-category-wrapper > * {
  display: none;
  position: relative;
  // background-color: $rojoBase2;
  &.active {
    display: block;
  }
}
