.content___preguntas {
  padding-top: 100px;
  padding-bottom: 50px;
  .preguntas {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    h1 {
      color: #da282f;
      font-size: 50px;
      font-family: 'CoreSansMSC87CnHeavy';
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 10px;
        display: flex;
        img {
          width: 90%;
        }
      }
    }
  }
  ul {
    width: 80%;
    max-width: 1200px;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    li {
      margin-bottom: 10px;
      p {
        margin: 0;
        color: #535353;
        font-size: 18px;
      }
      .tab__text {
        padding: 1.5% 3%;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f6f6f6;
        &::after {
          content: '';
          width: 40px;
          height: 40px;
          min-width: 40px;
          margin-left: 20px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../images/assets/icons/i-plus.svg');

        }
        &.active::after {
          background-image: url('../images/assets/icons/i-less.svg');
        }
      }
      .content__text {
        padding: 1% 3% 3%;
        font-size: 16px;
        line-height: 1.5;
        display: none;
        background-color: #f6f6f6;
      }
    }
  }
}

@media (max-width: 991px) {
  .content___preguntas {
    .preguntas {
      width: 95%;
      h1 {
        font-size: 35px;
        span img {
          width: 50%;
        }
      }
    }
    ul {
      width: 95%;
      li {
        p {
          font-size: 16px;
        }
        .tab__text {
          padding: 2.5% 4%;
        }
      }
      .content__text {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 552px) {
  .content___preguntas {
    .preguntas {
      width: 95%;
      h1 {
        font-size: 22px;
        span img {
          width: 40px;
        }
      }
    }
    ul {
      width: 95%;
      li .content__text {
        font-size: 14px;
      }
    }
  }
}
