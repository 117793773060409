.content___terminos {
  min-height: calc(100vh - 172px);
  padding-top: 100px;
  padding-bottom: 50px;
  .terminos {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    h1 {
      color: #da282f;
      font-size: 45px;
      font-family: 'CoreSansMSC87CnHeavy';
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-left: 10px;
        display: flex;
        img {
          width: 90%;
        }
      }
    }
    .content__img__aviso img {
      width: 100%;
      height: auto;
    }
  }

  .text__terminos {
    width: 80%;
    max-width: 1200px;
    padding: 30px;
    margin: 0 auto;
    background-color: #f6f6f6;
    &.content__text {
      width: 100%;
      .subtitle__termns {
        margin: 19px 0;
      }
    }
    p {
      color: #535353;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      &.title {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
      }
      &.number {
        padding-left: 30px;
        span {
          color: #da282f;
          font-weight: bold;
        }
      }
      &.subtitle__termns {
        color: #535353;
        font-size: 18px;
        font-family: 'CoreSansMSC87CnHeavy';
        line-height: 24px;
        &.red {
          color: #da282f;
        }
      }
    }
    .list ul li {
      color: #535353;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      img {
        width: 640px;
        max-width: 100%;
        margin: 15px auto 10px;
        display: block;
      }
    }
  }
}
.content___preguntas.accordion {
  width: 80%;
  max-width: 1200px;
  padding-top: 21px;
  margin: 0 auto;
  background-color: #ffffff;
  ul {
    width: 100%;
    .tab__text {
      color: #535353;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}

@media (max-width: 991px) {
  .content___terminos {
    .terminos {
      width: 95%;
      h1 {
        font-size: 35px;
        span img {
          width: 50%;
        }
      }
    }
    .text__terminos {
      width: 95%;
      padding: 15px;
    }
  }
}
@media (max-width: 552px) {
  .content___terminos {
    .terminos h1 {
      font-size: 22px;
      span {
        margin-left: 5px;
        img {
          width: 30px;
        }
      }
    }
    .text__terminos p {
      font-size: 14px;
    }
  }
}
