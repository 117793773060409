@keyframes modalanimation {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes modalContentAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.content__modal__nutrimental {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  .content__img__table {
    width: 80%;
    max-width: 672px;
    text-align: center;
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    #icon__close__modal {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      top: -20px;
      right: -20px;
      transition: transform 500ms;
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
  &.active {
    display: flex;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-name: modalContentAnimation;
    .content__img__table {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: modalanimation;
    }
  }
}
@media (max-width: 552px) {
  .content__modal__nutrimental .content__img__table {
    width: 85%;
  }
}
