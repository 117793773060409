.fadeInTitleMundo {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInTitleMundo;
}
.bounceInLeftAvatars {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounceInLeftAvatars;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
@keyframes bounceInLeftAvatars {
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.zoomInMap {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: zoomInMap;
}
@keyframes zoomInMap {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.bounceIn {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounceInDotsMap;
}
@keyframes bounceInDotsMap {
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.content__nissin__mundo {
  .content__countries {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $rojoBase;
    .data__countries {
      width: 100%;
      max-width: 1600px;
      padding: 0 2.5%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content__title__mobile__mundo {
        text-align: left;
        display: none;
        h1 {
          margin-bottom: 0;
          color: $color-blanco;
          font-size: 45px;
          font-family: 'CoreSansMSC87CnHeavy';
          letter-spacing: 1px;
          text-align: center;
          span {
            margin-left: 5px;
            img {
              padding-bottom: 13px;
            }
          }
        }
        p {
          margin: 0;
          color: $color-blanco;
          font-size: 18px;
          text-align: center;
        }
      }
      .content__avatar {
        width: 30%;
        color: $color-blanco;
        text-align: center;
        position: relative;
        z-index: 2;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: bounceInLeftAvatars;
        .content__carousel__mundo .item__mundo__carousel {
          img {
            max-width: 280px;
            margin: 0 auto;
          }
          .title__countrie {
            font-size: 30px;
            font-family: 'CoreSansMSC87CnHeavy';
            line-height: 1;
            letter-spacing: 1px;
            text-transform: uppercase;
          }
          .des__countrie {
            width: 60%;
            margin: 0 auto;
            font-size: 18px;
            font-weight: 200;
            line-height: 1.5;
          }
        }
        .content__nav__countries {
          padding: 0 5%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name__countrie {
            padding-bottom: 5px;
            color: #fff;
            font-size: 32px;
            font-family: 'AmsiProCond-Black';
            line-height: 1;
            text-transform: uppercase;
            p {
              margin: 0;
            }
          }
          [class *= 'icon__arrow'] {
            cursor: pointer;
            img {
              width: 60px;
            }
            &.icon__arrow__right img {
              transform: rotate(180deg);
            }
          }
        }
      }
      .content__map {
        width: 70%;
        h1 {
          margin: 0;
          color: $color-blanco;
          font-size: 50px;
          font-family: 'CoreSansMSC87CnHeavy';
          letter-spacing: 1px;
          text-align: center;
          animation-duration: 2s;
          animation-fill-mode: both;
          animation-name: fadeInTitleMundo;
          span {
            margin-left: 20px;
            animation-duration: 3s;
            animation-fill-mode: both;
            animation-name: fadeInTitleMundo;
            img {
              padding-bottom: 13px;
            }
          }
        }
        p {
          margin: 0;
          color: $color-blanco;
          font-size: 20px;
          text-align: center;
        }
        .content__map__points {
          position: relative;
          left: -40px;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-name: zoomInMap;
          @for $i from 1 through 14 {
            .dot__map {
              width: 2em * $i;
            }
          }
          .dot__map {
            width: 18px;
            height: 18px;
            border-radius: 100%;
            border: 1px solid $color-blanco;
            cursor: pointer;
            position: absolute;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-name: bounceInDotsMap;
            background: $rojoBase;
            &.line {
              &::after {
                transform-origin: left center;
              }
              &::before, &::after {
                content: '';
                height: 1px;
                position: absolute;
                top: 8px;
                background-color: white;
              }
            }
            &.big {
              width: 23px;
              height: 23px;
              &.line::before {
                top: 12px;
              }
            }
            &.activado {
              background-color: #F1FC46;
            }
            &:hover {
              background-color: $amarilloBase;
            }
            &.activado {
              z-index: 99;
              .content__box {
                display: flex !important;
              }
            }
            .content__box {
              width: 110px;
              height: 110px;
              display: none;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: -110px;
              right: -90px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url('../images/nosotros-mundo/nissin-nosotros-info.png');
              p {
                color: #6a6a6a;
                font-size: 14px;
                font-family: 'CoreSansMSC87CnHeavy';
                text-align: left;
                line-height: 1.2;
                position: relative;
                span {
                  color: #da282f;
                }
                &:before {
                  content: '';
                  width: 5px;
                  height: 5px;
                  display: block;
                  border-radius: 50px;
                  position: absolute;
                  top: 50%;
                  left: -10px;
                  transform: translateY(-50%);
                  background-color: #da282f;
                }
              }
            }
            &[data-id='México'] {
              left: 17.65%;
              top: 50%;
            }
            &[data-id='Estados Unidos'] {
              left: 17%;
              top: 35%;
            }
            &[data-id='Brasil'] {
              left: 31%;
              top: 68%;
            }
            &[data-id='Reino Unido'] {
              left: 46%;
              top: 27.5%;
            }
            &[data-id='Alemania'] {
              left: 48.5%;
              top: 30%;
            }
            &[data-id='India'] {
              left: 67.5%;
              top: 50%;
            }
            &[data-id='Tailandia'] {
              left: 72%;
              top: 53%;
            }
            &[data-id='Singapour'] {
              left: 74.5%;
              top: 61.5%;
            }
            &[data-id='Vietnam'] {
              left: 75.8%;
              top: 53.35%;
            }
            &[data-id='Indonesia'] {
              left: 80%;
              top: 62%;
            }
            &[data-id='Filipinas'] {
              left: 79.5%;
              top: 54.5%;
            }
            &[data-id='Hong Kong'] {
              left: 77.5%;
              top: 48.5%;
            }
            &[data-id='China'] {
              left: 74.2%;
              top: 43%;
            }
            &[data-id='Japón'] {
              left: 86.25%;
              top: 34%;
            }
            &[data-id='Hungría'] {
              left: 51%;
              top: 32%;
            }
            &[data-id='Guatemala'] {
              left: 15%;
              top: 57%;
              &::before {
                width: 35px;
                left: 17px;
              }
              &::after {
                width: 17px;
                left: 52px;
                transform: rotate(-50deg);
              }
            }
            &[data-id='El salvador'] {
              left: 16.5%;
              top: 60.75%;
              &::before {
                width: 16px;
                left: 17px;
              }
              &::after {
                width: 38px;
                left: 33px;
                transform: rotate(-53deg);
              }
            }
            &[data-id='Honduras'] {
              left: 23%;
              top: 50%;
              &::before {
                width: 10px;
                top: 16px;
                left: -4px;
                transform: rotate(-65deg);
                transform-origin: right center;
              }
            }
            &[data-id='Nicaragua'] {
              left: 25%;
              top: 54.65%;
              &::before {
                width: 16px;
                top: 10px;
                left: -16px;
                transform: rotate(0deg);
                transform-origin: right center;
              }
            }
            &[data-id='Costa Rica'] {
              left: 18%;
              top: 64.5%;
              &::before {
                width: 11px;
                left: 17px;
                top: 5px;
              }
              &::after {
                width: 39px;
                left: 28px;
                top: 5px;
                transform: rotate(-58deg);
              }
            }
            &[data-id='Panamá'] {
              left: 19.5%;
              top: 68%;
              &::before {
                width: 50px;
                top: 2px;
                left: 15px;
                transform: rotate(-58deg);
                transform-origin: left center;
              }
            }
          }

          @for $i from 1 through 21 {
            .dot__map:nth-child(#{$i}) {
              animation-delay: 0.2s * $i;
            }
          }
          img {
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .content__social__mundo {
    // padding-top: 80px;
    padding-top: 50px;
    padding-bottom: 80px;
    display: none;
    // background-image: url('../images/assets/bg_noodles.svg');
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: 30% 10%;
    // background-color: rgba($grisClaroBase, 0.2);
    background: #f5f5f5;
    &.active {
      display: block;
    }
    .content__title__nissin {
      padding: 10px 0;
      text-align: center;
      h2 {
        margin: 0;
        color: rgba($color-negro, 0.7);
        font-size: 60px;
        font-family: 'CoreSansMSC87CnHeavy';
        text-transform: uppercase;
        line-height: 1;
      }
      p {
        margin: 0;
        color: rgba($color-negro, 0.7);
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .subtext__social {
      text-align: center;
      p {
        margin-top: 0;
        color: rgba($color-negro, 0.7);
        font-size: 20px;
        font-weight: 600;
      }
    }
    .content__social__slider {
      max-width: 1600px;
      margin: 50px auto 0;
      position: relative;
      .content__data__slider {
        width: 80%;
        margin: 0 auto;
        .item__slider {
          border-radius: 15px;
          border: 5px solid rgba(#eee, 0.4);
          display: grid;
          grid-template-columns: 1fr 1fr;
          .img__social__slider {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            img {
              width: 100%;
              height: auto;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }
          }
          .data__social__slider {
            padding: 20px 30px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: $color-blanco;
            h2 {
              font-family: 'Lato';
              font-size: 30px;
            }
            p {
              margin: 0;
              line-height: 1.5;
              font-weight: 200;
            }
          }
        }
      }
      .arrow__right, .arrow__left {
        width: 50px;
        height: 50px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 5;
        transform: translateY(-50%);
      }
      .arrow__left {
        left: 5%;
      }
      .arrow__right {
        right: 5%;
      }
    }
  }
  .content__cupnoodles__museum {
    margin-bottom: 100px;
    .content__logo__museum img {
      width: 300px;
      margin: 100px auto 50px;
      display: block;
    }
    .subtext__museum {
      color: rgba($color-negro, 0.7);
      font-weight: 600;
      font-size: 28px;
      text-align: center;
    }
    .content__images__museum {
      width: 80%;
      max-width: 1200px;
      margin: 50px auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      p {
        margin: 0;
        color: $color-blanco;
        font-size: 20px;
        font-weight: 200;
        text-transform: uppercase;
        position: absolute;
      }
      img {
        width: 100%;
        height: auto;
        transition: transform 1s;
      }
      .left__pic {
        position: relative;
        overflow: hidden;
        &:hover {
          img {
            transform: scale(1.2);
          }
          p {
            text-decoration: underline;
          }
        }
        p {
          top: 5%;
          right: 5%;
        }
      }
      .right__pic {
        position: relative;
        overflow: hidden;
        &:hover {
          img {
            transform: scale(1.2);
          }
          p {
            text-decoration: underline;
          }
        }
        p {
          top: 25%;
          right: 5%;
        }
        img {
          padding-top: 100px;
        }
      }
    }
  }
}
// ============================
// media queries
// ============================

@media (max-width: 1200px) {
  .content__nissin__mundo {
    .content__social__mundo .content__social__slider .content__data__slider .item__slider .data__social__slider h2 {
      margin: 0 0 20px;
      font-size: 24px;
    }
    .content__countries .data__countries .content__map .content__map__points .dot__map {
      width: 13px;
      height: 13px;
      &.big {
        width: 18px;
        height: 18px;
      }
      &[data-id='Guatemala']{
        &::before {
          width: 30px;
          top: 4px;
          left: 11px;
        }
        &::after {
          width: 13px;
          top: 4px;
          left: 41px;
        }
      }
      &[data-id='El salvador'] {
        &::before {
          width: 14px;
          top: 2px;
          left: 11px;
        }
        &::after {
          width: 27px;
          top: 2px;
          left: 25px;
        }
      }
      &[data-id='Honduras']::before {
        top: 11px;
        left: -6px;
      }
      &[data-id='Nicaragua']::before {
        width: 13px;
        top: 6px;
        left: -14px;
      }
      &[data-id='Costa Rica'] {
        &::before {
          width: 16px;
          top: 2px;
          left: 11px;
        }
        &::after {
          width: 31px;
          top: 2px;
          left: 27px;
        }
      }
      &[data-id='Panamá']::before {
        width: 45px;
        top: 4px;
        left: 12px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .content__nissin__mundo .content__countries .data__countries .content__map .content__map__points .dot__map {
    width: 11px;
    height: 11px;
    &.big {
      width: 16px;
      height: 16px;
    }
    &[data-id='Guatemala'] {
      &::after {
        width: 9px;
        left: 38px;
        top: 3px;
      }
      &::before {
        width: 29px;
        left: 9px;
        top: 3px;
      }
    }
    &[data-id='El salvador'] {
      &::before {
        width: 18px;
        left: 8px;
        top: 1px;
      }
      &::after {
        width: 22px;
        top: 1px;
        left: 26px;
      }
    }
    &[data-id='Honduras']::before {
      top: 9px;
      left: -5px;
    }
    &[data-id='Nicaragua']::before {
      left: -13px;
    }
    &[data-id='Costa Rica'] {
      &::before {
        width: 11px;
        left: 9px;
      }
      &::after {
        width: 27px;
        left: 20px;
        top: 2px;
      }
    }
    &[data-id='Panamá']::before {
      width: 39px;
      top: 3px;
      left: 10px;
    }
  }
}
@media (max-width: 991px) {
  .content__nissin__mundo {
    .content__countries {
      height: auto;
      .data__countries {
        width: 95%;
        margin: 0 auto;
        flex-direction: column;
        .content__title__mobile__mundo {
          display: block;
        }
        .content__avatar {
          width: 100%;
          padding-top: 50px;
          padding-bottom: 50px;
          .content__nav__countries {
            width: 70%;
            margin: 0 auto;
          }
        }
        .content__map {
          h1.desktop, p.desktop, .content__map__points {
            display: none;
          }
        }
      }
    }
    .content__social__mundo {
      // background: none;
      // background-position: 10%;
      // background-size: contain;
      .content__title__nissin {
        h2 {
          font-size: 40px;
        }
        p {
          font-size: 20px;
        }
      }
      .content__social__slider {
        .arrow__left {
          display: none;
        }
        .content__data__slider .item__slider {
          grid-template-columns: 1fr;
          .img__social__slider img {
            border-bottom-left-radius: 0;
            border-top-right-radius: 10px;
          }
          .data__social__slider {
            h2 {
              font-size: 18px;
            }
            p {
              font-size: 14px;
            }
          }
        }
        .arrow__right {
          display: none;
        }
      }
    }
    .content__cupnoodles__museum {
      .content__logo__museum img {
        width: 35%;
        height: auto;
      }
      .content__images__museum {
        grid-template-columns: 1fr;
        .left__pic {
          position: relative;
          p {
            font-size: 25px;
            top: 50%;
            right: inherit;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .right__pic {
          position: relative;
          p {
            font-size: 25px;
            right: inherit;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          img {
            padding-top: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .content__nissin__mundo .content__cupnoodles__museum .subtext__museum {
    font-size: 20px;
  }
}
@media (max-width: 552px) {
  .content__nissin__mundo {
    .content__countries {
      .data__countries {
        width: 90%;
        .content__title__mobile__mundo {
          display: block;
          h1.mobile {
            margin-top: 50px;
            margin-bottom: 10px;
            font-size: 25px;
            span img {
              width: 5%;
              min-width: 30px;
              padding-bottom: 5px;
            }
          }
        }
        .content__avatar {
          .content__carousel__mundo .item__mundo__carousel {
            .des__countrie {
              font-size: 16px;
              width: 70%;
            }
            img {
              max-width: 200px;
            }
          }
          .content__nav__countries {
            width: 100%;
            margin-top: 30px;
            .icon__arrow__left img {
              width: 45px;
            }
            .icon__arrow__right img {
              width: 45px;
            }
          }
        }
      }
    }
    .content__social__mundo {
      background-position: 1%;
      .subtext__social p {
        font-size: 18px;
      }
      .content__social__slider .content__data__slider {
        width: 95%;
      }
    }
    .content__cupnoodles__museum .subtext__museum {
      font-size: 14px;
    }
  }
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $naranjaBase3;
}
.owl-theme .owl-dots .owl-dot span {
  background: #869791;
}
.owl-theme .owl-dots .owl-dot {
  outline: 0;
}
