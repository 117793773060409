.content__nissin__reponsabilidad {
  .d-sm-none {
    margin: 5px auto 13px;
    display: none;
  }
  h2 {
    padding: 0 5%;
    margin: 60px 0;
    font-size: 50px;
    font-family: 'CoreSansMSC87CnHeavy';
    text-align: center;
    letter-spacing: 0;
    line-height: 1;
    position: relative;
    .nowrap {
      white-space: nowrap;
      position: relative;
      img {
        width: 90px;
        margin-top: auto;
        margin-bottom: auto;
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }
    img {
      margin-left: 10px;
      vertical-align: middle;
    }
    &.red {
      color: #DA282F !important;
    }
    &.white {
      color: #ffffff !important;
    }
  }
  .content__social__mundo {
    // padding-top: 50px;
    // background: #f5f5f5;
    .contentResponsabilidad {
      margin-top: 40px;
      .wrapper {
        width: 100%;
        max-width: 1131px;
        margin: auto;
      }
      .inl {
        width: 100%;
        display: flex;
        align-items: center;
        .textWrapper {
          width: 48%;
          max-width: 496px;
          margin: 0 2%;
          p {
            color: #646464;
            font-size: 17px;
            letter-spacing: 0;
            line-height: 27px;
          }
        }
        .imageWrapper {
          width: 48%;
          margin: 0 2%;
          img {
            width: 100%;
            max-width: 570px;
          }
        }
      }
      .tableWrapper {
        width: 100%;
        max-width: 1131px;
        margin: auto;
        overflow-x: auto;
        background-color: #F5F5F5;
        .headerTable {
          height: 70px;
          min-width: 1024px;
          border-radius: 20px;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          background-color: #FFFFFF;
          p {
            width: calc(33% - (219px / 3));
            color: #DA282F;
            font-size: 20px;
            font-family: 'CoreSansMSC87CnHeavy';
            text-align: center;
            letter-spacing: 0;
            line-height: 27px;
            &:first-child {
              width: 209px;
            }
          }
        }
        .row {
          width: 100%;
          height: auto;
          min-width: 1024px;
          min-height: 70px;
          border-radius: 20px;
          margin-bottom: 10px;
          display: flex;
          background-color: #FFFFFF;
          .month {
            width: 209px;
            padding-left: 4%;
            border-right: 1px solid #A69494;
            display: flex;
            align-items: center;
            justify-content: normal;
            p {
              margin-left: 9px;
              color: #6A6A6A;
              font-size: 19px;
              font-family: 'CoreSansMSC87CnHeavy';
              letter-spacing: 0;
              line-height: 25px;
            }
            img {
              margin: 0;
            }
          }
          .rowContent {
            width: calc(100% - 209px);
            min-height: 70px;
            .content {
              min-height: 70px;
              display: flex;
              justify-content: space-between;
              &:nth-child(2) {
                border-top: 1px solid #A69494;
              }
              .item {
                width: 33%;
                padding: 1.85% 2% 1.85% 4%;
                border-right: 1px solid #A69494;
                display: flex;
                align-items: center;
                &:last-child {
                  border-right: none;
                }
                img {
                  width: auto;
                  height: 26px;
                  display: block;
                }
                p {
                  margin: 0 0 0 5%;
                  color: #464646;
                  font-size: 16px;
                  line-height: 19px;
                }
              }
            }
          }
        }
      }
      .wrapperGray {
        padding: 32px 0;
        background-color: #F5F5F5;
        &:first-child {
          padding-top: 10px;
        }
        .wrapperYellow {
          width: 100%;
          max-width: 956px;
          padding: 3% 2.5%;
          margin: 34px auto 0;
          background-repeat: repeat;
          background-image: url(../images/home/conoce/nissin-nosotros-bg.png);
          .inl {
            align-items: center;
            img {
              width: 100%;
              max-width: 363px;
            }
            p {
              width: 100%;
              max-width: 466px;
              padding-left: 4%;
              margin: 0;
              font-size: 17px;
              letter-spacing: 0;
              line-height: 27px;
            }
          }
        }
        button {
          width: 100%;
          max-width: 328px;
          margin: 40px auto;
          display: block;
        }
      }
      .wrapperRed {
        padding: 32px 0;
        background-color: #DA282F;
        h2 {
          margin: 30px auto 16px;
        }
        .circles {
          width: 100%;
          max-width: 1185px;
          margin: 50px auto 0;
          align-items: flex-start;
          justify-content: center;
          .item {
            width: 25%;
            img {
              width: 135px;
              margin: auto;
              display: block;
            }
            p {
              color: #ffffff;
              font-family: 'CoreSansMSC87CnHeavy';
              font-size: 22px;
              letter-spacing: 0;
              line-height: 29px;
              text-align: center;
              span {
                color: #F1FC46;
              }
              &.light {
                font-size: 14px;
                font-weight: 200;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
              }
            }
          }
        }
        .subtitle {
          margin-top: 16px;
          color: #FFFFFF;
          font-size: 17px;
          text-align: center;
          letter-spacing: 0;
          line-height: 22px;
        }
        .textWrapper {
          p {
            color: #ffffff;
            font-size: 17px;
            letter-spacing: 0;
            line-height: 27px;
          }
          img {
            width: 100%;
            max-width: 155px;
            margin-bottom: 19px;
          }
        }
        &.whats .wrapper {
          margin-top: 50px;
          .imageWrapper img {
            max-width: 475px;
          }
        }
      }
      .wrapperWhite {
        padding: 32px 0;
        background-color: #ffffff;
        h2 {
          padding: 0;
          margin: 0;
          font-size: 30px;
          line-height: 40px;
          text-align: left;
        }
      }
      .quoteWrapper {
        padding: 47px 0;
        p {
          width: 100%;
          max-width: 925px;
          margin: 0 auto;
          color: #646464;
          font-size: 22px;
          font-family: 'CoreSansMSC87CnHeavy';
          text-align: center;
          letter-spacing: 0;
          line-height: 34px;
          span {
            color: #DA282F;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 850px) {
  .content__nissin__reponsabilidad .content__social__mundo .contentResponsabilidad {
    .wrapper.inl {
      flex-direction: column;
      .textWrapper, .imageWrapper {
        width: 100%;
        max-width: none;
        margin: 0;
      }
      .textWrapper {
        padding: 0 5% 7.5%;
      }
      .imageWrapper {
        order: 2;
        img {
          margin: auto;
          display: block;
        }
      }
    }
    .quoteWrapper {
      padding: 3.5% 5% 8.5%;
    }
    .wrapperYellow .inl {
      flex-direction: column;
      justify-content: center;
      p {
        max-width: none !important;
        margin: 15px auto 0 !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .content__nissin__reponsabilidad {
    .d-sm-none {
      display: block;
    }
    .content__social__mundo .contentResponsabilidad {
      margin-top: 20px;
      .wrapperGray {
        padding: 15px 0;
        h2 {
          margin: 35px 0;
        }
        br {
          display: none;
        }
      }
      .wrapper.inl .textWrapper {
        padding-bottom: 5%;
      }
      .wrapperRed .circles {
        padding: 0 5%;
        flex-wrap: wrap;
        .item {
          width: 50%;
          padding: 0 1%;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .content__nissin__reponsabilidad .content__social__mundo {
    padding-top: 35px;
    h2 {
      margin: 0 auto 16px !important;
      font-size: 25px !important;
      line-height: 25px !important;
      .nowrap {
        img {
          width: 45px;
        }
        &:not(.line) {
          display: block;
        }
      }
    }
    .contentResponsabilidad {
      margin-top: 0;
      .wrapperWhite h2 {
        text-align: center;
      }
      .wrapperWhite, .wrapperGray {
        padding: 20px 0;
      }
      .quoteWrapper {
        padding-bottom: 3.5%;
        p {
          font-size: 16px;
          text-align: left;
          line-height: 23px;
        }
      }
      .wrapperRed .subtitle, .inl .textWrapper p {
        font-size: 16px;
      }
      .wrapperRed {
        .subtitle {
          padding: 0 5%;
          br {
            display: none;
          }
        }
        .textWrapper img {
          margin-right: auto;
          margin-left: auto;
          display: block;
        }
        .circles {
          padding-left: 8%;
          margin-top: 32px;
          .item {
            width: 100%;
            padding-bottom: 14px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 70px;
              margin: 0 15px 0 0;
            }
            p {
              max-width: calc(100% - 85px);
              margin: 0;
              text-align: left;
              font-size: 18px;
              line-height: 24px;
              &.light {
                max-width: 100%;
                margin-top: 12px;
                text-align: center;
              }
            }
            &:nth-child(3) {
              align-items: flex-start;
            }
            &:nth-child(3) br, &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
}