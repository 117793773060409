// ===========================
// colores
// ===========================
$rojoBase: #da282f;
$rojoBase2: #9e1016;
$verdeBase: #71ad62;
$verdeBase2: #7fac53;
$amarilloBase: #d4a753;
$amarilloBase2: #d3b639;
$amarilloBaseLine: #f1fc46;
$naranjaBase: #f07034;
$naranjaBase2: #d8971d;
$naranjaBase3: #faad1d;
$grisClaroBase: #d8d8d8;
$grisClaroBase2: #a69494;
$color-blanco: #fff;
$color-negro: #000;
// ===========================
// fuentes
// ===========================
@font-face {
  font-display: swap;
  font-family: 'CoreSansMSC87CnHeavy';
  src: url('https://www.nissinfoods.com.mx/css/fonts/CoreSansMSC87CnHeavy.woff') format('woff'),
      url('https://www.nissinfoods.com.mx/css/fonts/CoreSansMSC87CnHeavy.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Lato';
  src: url('https://www.nissinfoods.com.mx/css/fonts/Lato-Regular.woff') format('woff'),
      url('https://www.nissinfoods.com.mx/css/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-family: 'Lato';
  src: url('https://www.nissinfoods.com.mx/css/fonts/Lato-Bold.woff') format('woff'),
      url('https://www.nissinfoods.com.mx/css/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-family: 'Lato';
  src: url('https://www.nissinfoods.com.mx/css/fonts/Lato-Light.woff') format('woff'),
      url('https://www.nissinfoods.com.mx/css/fonts/Lato-Light.ttf') format('truetype');
  font-weight: 200;
}
/** Generated by FG **/
@font-face {
  font-display: swap;
  font-family: 'AmsiProCond-Black';
  src: url('https://www.nissinfoods.com.mx/css/fonts/AmsiProCond-Black.woff') format('woff'),
      url('https://www.nissinfoods.com.mx/css/fonts/AmsiProCond-Black.ttf') format('truetype');
}
// ===========================
// quitar el scroll
// ===========================
html, body {
  min-height: 100%;
  &.no-scroll-2 {
    overflow-y: hidden;
  }
  @media screen and (max-width: 991px) {
    &.no-scroll {
      height: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
}

// ===========================
// animaciones
// ===========================
@keyframes explode {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
    background-color: rgba(0, 0, 0, 0.5);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    background-color: rgba(0, 0, 0, 0.8);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.lightSpeedIn {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}
@keyframes lightSpeedIn {
  0% {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-5deg);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

.bounceInUp {
  animation-name: bounceInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes bounceInUp {
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: slideInUp;
}
@keyframes slideInUp {
  0% {
    transform: translateY(150%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}

.col-1 {
  width: 100%;
}
.col-2 {
  width: 50%;
}

.m-auto, .my-auto, .mt-auto {
  margin-top: auto;
}
.m-auto, .my-auto, .mb-auto {
  margin-bottom: auto;
}
.m-auto, .mx-auto, .ml-auto {
  margin-left: auto;
}
.m-auto, .mx-auto, .mr-auto {
  margin-right: auto;
}
.m-0, .my-0, .mt-0 {
  margin-top: 0;
}
.m-0, .my-0, .mb-0 {
  margin-bottom: 0;
}
.m-0, .mx-0, .ml-0 {
  margin-left: 0;
}
.m-0, .mx-0, .mr-0 {
  margin-right: 0;
}

.txt-center {
  text-align: center;
}
.txt-preline {
  white-space: pre-line;
}
.clr-white {
  color: white;
}

.inl {
  display: flex;
  align-items: center;
}
.flex-center {
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .col-lg-1 {
    width: 100%;
  }
  .txt-lg-normal {
    white-space: normal;
  }
  .d-lg-none {
    display: none;
  }
}
