.content__maxisopa .content__main__banner__maxisopa {
  margin-top: 80px;
}
.maxisopa {
  // --c-maxi-pollo: #ffdf1c;
  --c-maxi-pollo: #e4c704;
  --c-maxi-res: #83332e;
  --c-maxi-camaron: #ea7faf;

  --c-bg-from: #a2d000;
  --c-bg-to: #6c840d;

  position: relative;
  .slide {
    background-size: auto 100%;
    height: 38rem;
    &[data-id] {
      position: relative;
      // background-color: #000;
      background: linear-gradient(
          to bottom right,
          var(--c-bg-from),
          var(--c-bg-to)
        )
        no-repeat border-box;

      &::after,
      &::before {
        position: absolute;
        background-color: #000;
        content: '';
        width: 100%;
        height: 1.6rem;
        bottom: 0;
        left: 0;
      }
    }
    .content__slide .right__side .iconos__detalles {
      margin-top: 1rem;
      margin: 1rem 0 0;
      .detalle {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 20px;
        &.flavor {
          img {
            margin-top: -0.6rem;
            width: 7.2rem;
          }
        }
        p {
          margin: 1rem 0 0;
          width: 100%;
          height: auto;
          line-height: 1.2;
        }
      }
    }
    .left__side img {
      height: auto;
      width: 80%;
      max-width: initial;
    }
  }
  .owl-dots {
    background-color: var(--c-maxi-pollo);
    &[data-id='0'] {
      background-color: var(--c-maxi-pollo);
    }
    &[data-id='1'] {
      background-color: var(--c-maxi-res);
    }
    &[data-id='2'] {
      background-color: var(--c-maxi-camaron);
    }
    .owl-dot {
      &:nth-child(1) span {
        &::before {
          content: 'Pollo';
        }
        &::after {
          background-color: var(--c-maxi-pollo);
        }
      }
      &:nth-child(2) span {
        &::before {
          content: 'Jugo de carne';
        }
        &::after {
          background-color: var(--c-maxi-res);
        }
      }
      &:nth-child(3) span {
        &::before {
          content: 'Camarón picante';
        }
        &::after {
          background-color: var(--c-maxi-camaron);
        }
      }
      span::after {
        background-color: #508344;
      }
    }
  }
}


.modo__preparacion__maxisopa {
  background-size: contain;
  background-image: url('../images/home/nissin-maxisopa-pattern.png');
  .content__title__nissin {
    padding: 10px 0;
    text-align: center;
    h2 {
      margin: 0;
      color: rgba($color-negro, 0.7);
      font-size: 80px;
      font-family: 'CoreSansMSC87CnHeavy';
      text-transform: uppercase;
    }
    p {
      margin: 0;
      color: rgba($color-negro, 0.7);
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .pasos {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto 150px;
    display: flex;
    .opcion__1 {
      width: 75%;
      display: flex;
      & > div {
        width: 33%;
        position: relative;
        .indicacion {
          color: #fff;
          position: absolute;
          top: 100%;
          left: 0;
          p {
            width: 75%;
            margin: 0 auto;
            color: #464646;
            font-family: 'CoreSansMSC87CnHeavy';
            font-size: 16px;
            letter-spacing: 0.5px;
            text-align: center;
            span:not(.measurement) {
              color: #da282f;
            }
            span.measurement {
              font-family: "Quicksand", sans-serif;
              font-optical-sizing: auto;
              font-weight: 700;
              font-style: normal;
            }
          }
        }
        &:nth-child(1) {
          right: -160px;
        }
        &:nth-child(2) {
          right: -80px;
          .indicacion p {
            width: 48%;
          }
        }
        &:nth-child(3) {
          .indicacion p {
            width: 60%;
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            right: 8%;
            top: 15%;
            background: #da282f;
            height: 230px;
            width: 4px;
            border-radius: 5px;
          }
        }
      }
    }
    .opcion__2 {
      width: 25%;
      display: flex;
      & > div {
        position: relative;
        .indicacion {
          position: absolute;
          color: #fff;
          top: 100%;
          left: 0;
          p {
            width: 75%;
            margin: 0 auto;
            color: #464646;
            font-size: 16px;
            font-family: 'CoreSansMSC87CnHeavy';
            text-align: center;
            letter-spacing: 0.5px;
            span {
              color: #da282f;
            }
          }
          small {
            width: 80%;
            margin: 10px auto 0;
            color: #464646;
            font-size: 14px;
            font-family: 'Lato';
            text-align: center;
            display: block;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .content__maxisopa .content__main__banner__maxisopa {
    margin-top: 60px;
  }
  .maxisopa .slider__productos .owl-nav {
    bottom: 130px;
  }
  .maxisopa .slide {
    background-size: auto 100%;
    height: calc(100vh - 120px);
    height: calc(100svh - 120px);
    flex-direction: column;
    justify-content: flex-start;
    padding: 4rem 0 0;
    min-height: 820px;
    .content__slide .left__side {
      height: 18rem;
      overflow: hidden;
      padding: 0;
      img {
        height: 100%;
        width: auto;
      }
    }
    .content__slide .right__side {
      margin-top: 1rem;
      margin-bottom: 0.6rem;
      h2 {
        height: 2.2em;
        margin-bottom: 1rem;
        &.mobile-single {
          height: 1.1em;
        }
      }
      .iconos__detalles {
        margin-bottom: 0;
        margin-top: 0;
        .detalle {
          margin-right: 0;
          &.flavor {
            img {
              margin-top: -0.2rem;
              width: 4rem;
            }
          }
        }
        &.color-black {
          color: #000;
          p {
            color: #000;
          }
        }
      }
    }
  }
  .modo__preparacion__maxisopa {
    .content__title__nissin {
      h2 {
        font-size: 75px;
      }
      p {
        font-size: 30px;
      }
    }
    .pasos {
      flex-direction: column;
      .opcion__1 {
        width: 100%;
        flex-direction: column;
        align-items: center;
        & > div {
          width: 33%;
          min-width: 280px;
          position: relative;
          .indicacion {
            z-index: 2;
          }
          &:nth-child(1) .indicacion p {
            width: 95%;
          }
          &:nth-child(2) {
            margin-top: 50px;
            .indicacion p {
              width: 95%;
            }
          }
          &:nth-child(3) {
            .indicacion {
              top: 92%;
              p {
                width: 95%;
              }
            }
            &:after {
              display: none;
            }
          }
        }
      }
      .opcion__2 {
        width: 100%;
        justify-content: center;
        & > div {
          position: relative;
          .indicacion {
            color: #fff;
            position: absolute;
            top: 100%;
            left: 0;
            p {
              width: 75%;
              margin: 0 auto;
              color: #464646;
              font-size: 16px;
              font-family: 'CoreSansMSC87CnHeavy';
              letter-spacing: 0.5px;
              text-align: center;
              span {
                color: #da282f;
              }
            }
            small {
              width: 80%;
              margin: 10px auto 0;
              color: #464646;
              font-size: 14px;
              font-family: 'Lato';
              text-align: center;
              display: block;
            }
          }
        }
      }
    }
  }
}
.d-none {
  display: none !important;
}
@media (max-width: 991px) {
  .d-mobile {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .d-desktop {
    display: flex !important;
  }
  .maxisopa .slide {
    height: calc(100svh - 150px);
    .content__slide .left__side{
      padding: 4vh 0;
    }
  }
  .nav__noodles .content__nav__noodles {
    position: relative;
    height: 65px;
    .right__side {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }
  .maxisopa .slide .left__side img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1;
    max-width: initial;
  }
}
@media (min-width: 1100px) {
  .nav__noodles .content__nav__noodles {
    height: 67.5px;
  }
}

@media (max-width: 1200px) {
  .modo__preparacion__maxisopa .pasos .opcion__1 > div {
    &:nth-child(1) {
      right: 0px;
    }
    &:nth-child(2) {
      right: 0px;
    }
  }
}
