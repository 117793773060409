.spinner__site {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -5;
  transition-delay: 0s, 0s, .4s;
  transition-duration: .375s, .375s, 0s;
  transition-property: opacity, visibility, z-index;
  background: rgba($rojoBase, 0.9);
  p {
    color: #fff;
    font-size: 30px;
    font-family: 'CoreSansMSC87CnHeavy';
  }
  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 9999999;
  }
}
.pastaSpinner {
  height: 551px;
  width: 153px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../images/assets/pasta__loader__sprite__2.png');
}
