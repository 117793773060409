
.content__nosotros {
  margin-top: 80px;
  .nav__nosotros {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      padding: 20px 20px;
      border: none;
      color: $rojoBase2;
      font-size: 20px;
      font-family: 'AmsiProCond-Black';
      letter-spacing: 1px;
      text-transform: uppercase;
      outline: 0;
      background: transparent;
      &:hover, &.active {
        color: #fff;
        background: $rojoBase2;
      }
    }
  }
  .content__nissin__mundo_1 {
    display: none;
    &.active {
      display: block;
    }
  }
  .content__historia__legado {
    display: none;
    &.active {
      display: block;
    }
    .filosofia {
      padding: 20px 0 60px;
      background: #f6f6f6;
      .content__filosofia {
        width: 95%;
        max-width: 1100px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        & > div {
          width: 50%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
        .left__side {
          padding-top: 80px;
          padding-right: 20px;
          align-items: flex-start;
          h3 {
            margin: 0 0 20px;
            color: #da282f;
            font-size: 60px;
            font-family: 'CoreSansMSC87CnHeavy';
            letter-spacing: 1px;
            text-transform: uppercase;
          }
          p {
            color: #464646;
            font-size: 16px;
            span {
              font-weight: 600;
            }
          }
        }
        .right__side {
          padding-left: 20px;
          p {
            width: 70%;
            margin: 20px auto;
            color: #da282f;
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }
    }
    .valores {
      padding: 50px 0 60px;
      position: relative;
      .content__box__frase {
        width: 240px;
        height: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 5%;
        right: 2%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../images/nosotros-mundo/nissin-frases-bg.png');
        p {
          width: 77%;
          margin: 0;
          color: #6a6a6a;
          font-size: 16px;
          font-family: 'AmsiProCond-Black';
          line-height: 1.5;
          text-align: center;
          span {
            color: #da282f;
          }
        }
      }
      .content__title__nissin {
        padding: 10px 0;
        text-align: center;
        h2 {
          margin: 0;
          color: rgba($color-negro, 0.7);
          font-size: 60px;
          font-family: 'CoreSansMSC87CnHeavy';
          text-transform: uppercase;
        }
      }
      .content__valores {
        width: 95%;
        max-width: 1100px;
        margin: 40px auto 10px;
        display: flex;
        justify-content: space-between;
        .square__valores {
          width: 22%;
          height: 200px;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.11);
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-evenly;
          img {
            width: 100%;
            height: auto;
          }
          p {
            color: #da282f;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
          }
          &:nth-child(2), &:nth-child(4) {
            margin-top: 30px;
          }
        }
      }
    }
    .momofuku {
      background-color: #c61f1a;
      .content__momofuku {
        width: 95%;
        max-width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        .left__side {
          width: 50%;
          h3 {
            margin: 0;
            color: #fff;
            font-size: 40px;
            font-family: 'AmsiProCond-Black';
            line-height: 1;
            letter-spacing: 1px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 80px;
              margin-left: 10px;
            }
          }
          p {
            width: 90%;
            margin-top: 30px;
            margin-bottom: 0;
            color: #fff;
            font-size: 16px;
            line-height: 1.5;
            span {
              font-weight: 600;
            }
          }
        }
        .right__side {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding-top: 50px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .momofuku__historia {
      .content__intro__text {
        p {
          width: 45%;
          margin: 40px auto;
          color: #646464;
          font-size: 22px;
          font-family: 'CoreSansMSC87CnHeavy';
          letter-spacing: 1px;
          text-transform: uppercase;
          span {
            color: #d2221d;
          }
        }
        &.wide p {
          width: 60%;
        }
      }
      .row {
        width: 95%;
        max-width: 1200px;
        margin: 0 auto 50px;
        display: flex;
        justify-content: space-between;
        & > div {
          width: 50%;
          img {
            width: 100%;
            height: auto;
          }
        }
        .text__side {
          padding: 0 5%;
        }
        p {
          margin-top: 0;
          margin-bottom: 30px;
          color: #464646;
          font-size: 16px;
          line-height: 1.5;
          span {
            font-weight: 600;
            &.italic {
              font-style: italic;
            }
          }
          &.hight__text {
            color: #646464;
            font-size: 22px;
            font-family: 'AmsiProCond-Black';
            letter-spacing: 1px;
            line-height: 1.2;
            span {
              color: #d2221d;
              font-family: 'AmsiProCond-Black';
            }
          }
        }
        .top__margin__img {
          margin-top: 50px;
        }
        .base__imagen {
          margin-bottom: 0;
          margin-top: 5px;
          font-size: 11px;
        }
      }
    }
  }
  .frases {
    padding: 15px 0;
    position: relative;
    background-color: #c61f1a;
    .content__frases {
      width: 95%;
      max-width: 800px;
      margin: 30px auto;
      .frase {
        text-align: center;
        h3, p {
          margin: 0;
          text-transform: uppercase;
        }
        h3 {
          color: #f1fc46;
          font-size: 38px;
          font-family: 'CoreSansMSC87CnHeavy';
        }
        p {
          width: 60%;
          margin: 30px auto;
          color: #fff;
          font-size: 30px;
          font-family: 'AmsiProCond-Black';
          span {
            color: #f1fc46;
          }
        }
			}
			.owl-nav {
				margin: auto;
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				button {
					width: 50px;
					height: 50px;
					border: none;
					border-radius: 50%;
					outline: none;
					position: absolute;
					transform: translateY(-50%);
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					span {
						display: none;
					}
					&.owl-prev {
						left: -13%;
						background-image: url('../images/assets/icons/left-slider.svg');
					}
					&.owl-next {
						right: -13%;
						background-image: url('../images/assets/icons/right-slider.svg');
					}
				}
			}
      .owl-dots {
        margin-top: 20px;
        .owl-dot.active span {
          background: #f1fc46;
        }
      }
    }
  }
  .content__nissin__historia {
    padding-bottom: 80px;
    background-color: rgba($grisClaroBase, 0.1);
    .content__title__nissin {
      padding: 10px 0;
      text-align: center;
      h2, p {
        margin: 0;
        color: rgba($color-negro, 0.7);
        text-transform: uppercase;
      }
      h2 {
        font-size: 80px;
        font-family: 'CoreSansMSC87CnHeavy';
      }
      p {
        font-size: 30px;
        font-weight: 600;
      }
    }
    .main__text__historia {
      color: rgba($color-negro, 0.7);
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }
    .content__imgs__historia {
      margin: 80px auto;
      .content__img img {
        width: 100%;
        height: auto;
        padding-top: 50px;
        opacity: 0.5;
        transition-duration: 1s;
        transition-property: opacity;
        &.active {
          opacity: 1 !important;
        }
      }
    }
    .content__years__historia {
      width: calc(80% + 48px);
      max-width: 1200px;
      padding-bottom: 20px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      overflow: auto;
      .content__date {
        margin: 0 24px;
        text-decoration: none;
        display: block;
        position: relative;
        &::before, &::after {
          content: '';
          width: 16px;
          height: 3px;
          margin: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          background-color: #e8e9ed;
        }
        &::before {
          left: -15px;
          transform: translateX(-100%);
        }
        &::after {
          right: -15px;
          transform: translateX(100%);
        }
        p {
          margin: 0;
          color: #e8e9ed;
          font-size: 30px;
          font-family: 'CoreSansMSC87CnHeavy';
          text-align: center;
          position: relative;
          &:hover {
            color: #faad1d;
          }
        }
        img {
          width: 90px;
          height: auto;
          margin-top: 10px;
          display: block;
          visibility: hidden;
        }
        &.active {
          p {
            color: #faad1d;
          }
          img {
            visibility: visible;
          }
        }
      }
      .content__line__year {
        width: 50px;
        height: 40px;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .line {
          width: 90%;
          height: 5px;
          background-color: #e8e9ed;
        }
      }
    }
    .content__text__years {
      width: 80%;
      max-width: 1200px;
      margin: 0 auto;
      .text__years {
        color: #a69494;
        font-size: 18px;
        line-height: 1.5;
      }
    }
  }
}
.owl-item .content__img img.selected__img {
  padding-top: 0px !important;
  opacity: 1 !important;
}
@media (max-width: 991px) {
  .content__nosotros {
    margin-top: 80px;
    .content__historia__legado {
      .filosofia .content__filosofia {
        flex-direction: column-reverse;
        & > div {
          width: 100%;
        }
        .left__side {
          padding-top: 0px;
          padding-right: 0px;
          h3 {
            font-size: 40px;
          }
          p {
            font-size: 14px;
          }
        }
        .right__side {
          padding-left: 0px;
          p {
            width: 100%;
            font-size: 20px;
            text-align: center;
          }
        }
      }
      .valores {
        padding: 50px 0 60px;
        position: relative;
        .content__box__frase {
          display: none;
        }
        .content__title__nissin h2 {
          font-size: 40px;
        }
        .content__valores {
          flex-direction: column;
          .square__valores {
            width: 40%;
            min-width: 300px;
            border-radius: 0px;
            border-bottom: 1px solid #646464;
            box-shadow: none;
            margin: 0 auto;
            p {
              font-size: 14px;
            }
            &:nth-child(2), &:nth-child(4) {
              margin-top: 0px;
            }
            &:nth-child(4) {
              border-bottom: none;
            }
          }
        }
      }
      .momofuku .content__momofuku {
        flex-direction: column;

        .left__side {
          width: 100%;
          padding-top: 60px;
          p {
            margin-top: 50px;
            font-size: 14px;
          }
        }
        .right__side {
          width: 100%;
          padding-top: 50px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .momofuku__historia {
        .content__intro__text {
          p {
            width: 95%;
            font-size: 20px;
          }
          &.wide p {
            width: 95%;
          }
        }
        .row {
          margin: 0 auto;
          flex-direction: column;
          & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            img {
              max-width: 450px;
              margin: 0 auto 30px;
            }
          }
          .text__side {
            padding: 0%;
          }
          p {
            font-size: 14px;
          }
          .base__imagen {
            width: 450px;
            margin: 0 auto;
          }
        }
      }
    }
    .frases {
      padding: 15px 0;
      position: relative;
      background: #c61f1a;
      & > img {
        width: 50px;
        cursor: pointer;
        position: absolute;
        top: inherit;
        bottom: 0;
        left: 13%;
        transform: translateY(-50%);
        &:nth-child(2) {
          right: 13%;
          left: inherit;
        }
      }
      .content__frases {
        .frase {
          h3 {
            width: 75%;
            margin: 0 auto;
            font-size: 30px;
          }
          p {
            width: 75%;
            font-size: 25px;
          }
        }
        .owl-dots {
          margin-top: 20px;
          .owl-dot.active span {
            background: #f1fc46;
          }
				}
				.owl-nav {
					top: auto;
					bottom: 15px;
					button {
						width: 40px;
						height: 40px;
						&.owl-prev {
							left: 0;
						}
						&.owl-next {
							right: 0;
						}
					}
				}
      }
    }
    .content__nissin__historia {
      .content__title__nissin {
        h2 {
          font-size: 50px;
        }
        p {
          font-size: 20px;
        }
      }
      .main__text__historia {
        margin: 0;
        font-size: 16px;
      }
      .content__imgs__historia {
        margin: 50px auto 10px;
      }
      .content__years__historia {
        overflow-y: hidden;
      }
      .content__text__years .text__years {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 552px) {
  .content__nosotros {
    margin-top: 60px;
    .nav__nosotros {
      height: 50px;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      z-index: 3;
      button {
        width: 100%;
        min-height: 50px;
        padding: 15px 10px;
        margin-top: -50px;
        font-size: 16px;
        pointer-events: none;
        transition-duration: 0.2s;
        transition-property: margin;
        background-color: white;
        &.active {
          margin-top: 0;
          z-index: 1;
        }
      }
      &.open button {
        margin-top: 0;
        pointer-events: visible;
      }
    }
    .content__historia__legado {
      .filosofia .content__filosofia {
				.left__side p span {
					font-size: 12px;
				}
				.right__side img {
					max-width: 200px;
				}
      }
      .valores {
        padding: 50px 0 60px;
        position: relative;
        .content__box__frase {
          display: none;
        }
        .content__title__nissin h2 {
          font-size: 40px;
        }
        .content__valores {
          width: 100%;
          flex-direction: column;
          .square__valores {
            width: 100%;
            min-width: 100%;
            p {
              font-size: 18px;
            }
            &:nth-child(1) {
              border-top: 1px solid #646464;
            }
            img {
              max-width: 250px;
            }
          }
        }
      }
      .momofuku .content__momofuku .left__side p {
				width: 100%;
			}
      .momofuku__historia {
        .content__intro__text {
          p {
            font-size: 16px;
          }
          &.wide p {
            width: 95%;
          }
        }
        .row {
          p.hight__text {
            font-size: 16px;
          }
          .base__imagen {
            width: 95%;
            margin: 0 auto;
          }
        }
      }
    }
    .frases {
      & > img {
        width: 40px;
        bottom: 3%;
        left: 2%;
        &:nth-child(2) {
          right: 2%;
        }
      }
      .content__frases {
        .frase {
          h3 {
            width: 95%;
            margin: 0 auto;
            font-size: 25px;
          }
          p {
            width: 90%;
            font-size: 18px;
          }
				}
				.owl-nav {
					display: none;
				}
      }
    }
    .content__nissin__historia {
      .main__text__historia {
        font-size: 14px;
      }
      .content__imgs__historia {
        margin: 10px auto;
      }
      .content__text__years .text__years {
				font-size: 14px;
			}
    }
  }
}
