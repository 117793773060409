.content__ufo .content__main__banner__ufo {
  margin-top: 80px;
}
.content__back__nav {
  display: flex;
  align-items: center;
  position: absolute;
  left: 10%;
  top: 3%;
  z-index: 5;
  a {
    margin: 0;
    color: #fff;
    font-size: 20px;
    font-family: 'AmsiProCond-Black';
    text-transform: uppercase;
    letter-spacing: 1px;
    img {
      margin-right: 10px;
    }
    span {
      color: $rojoBase;
    }
    &:hover {
      color: #fff;
    }
  }
}
.ufo {
  position: relative;
  .slide {
    background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ufo/banners/bg-desktop.webp') !important;
    @media (max-width: 991px) {
      .content__slide {
        .left__side {
          width: 100%;
          img {
            margin: 0 auto 0;
            width: 100%;
            max-width: initial;
            max-height: initial;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
    @media (min-width: 992px) {
      .content__slide {
        height: 100%;
        .left__side {
          height: 100%;
          img {
            height: 100%;
            width: 100%;
            margin-top: 2%;
            object-fit: contain;
            max-width: initial;
          }
        }
      }
    }
  }
  .owl-dots {
    background-color: #2b2b2b;
    .owl-dot {
      &:nth-child(1) span::before {
        content: 'Res teriyaki';
      }
      &:nth-child(2) span::before {
        content: 'Camarón fuego';
      }
      // &:nth-child(3) span::before {
      //   content: 'alitas hot';
      // }
      &:nth-child(3) span::before {
        content: 'arrachera toreada';
      }
      span::after {
        background-color: #2b2b2b;
      }
    }
  }
}

.slider__productos {
  .owl-nav {
    bottom: 80px;
    height: 2rem;
    width: calc(100% - 2rem);
    position: absolute;
    left: 1rem;
    button {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 2%;
      outline: none;
      background: none !important;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-image: url('../images/assets/icons/left-slider.svg') !important;
      &.owl-next {
        right: 2%;
        left: auto;
        background-image: url('../images/assets/icons/right-slider.svg') !important;
      }
      span {
        display: none;
      }
    }
  }
  .owl-dots .owl-dot {
    span {
      width: auto;
      height: auto;
      padding: 23px 10px;
      border-radius: 0;
      margin: 0 30px;
      position: relative;
      background: none !important;
      &::before {
        padding-bottom: 5px;
        border-bottom: 2px solid transparent;
        color: #fff;
        font-size: 18px;
        font-family: 'AmsiProCond-Black';
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      &::after {
        content: '';
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        top: -15%;
        left: 50%;
        opacity: 0;
        transform: translateX(-50%) rotate(45deg);
      }
    }
    &.active span {
      &::before {
        border-color: #f1fc46;
      }
      &::after {
        opacity: 1;
      }
    }
  }
  @media (min-width: 992px) {
    .owl-nav {
      bottom: initial;
      top: calc(50% - 25px);
      height: 50px;
    }
  }
}

.modo__preparacion__noodles.ufo__preparacion .pasos > div {
  position: relative;
  left: 0;
}

.modo__preparacion__noodles.ufo__preparacion .videoWrapper {
  position: relative;
  width: calc(100% - 2rem);
  max-width: 960px;
  margin: 0 auto 50px;
}
.modo__preparacion__noodles.ufo__preparacion .videoArea {
  position: relative;
  padding: 0 0 56.52%;
  height: 0;
}
.modo__preparacion__noodles.ufo__preparacion .videoArea iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media (max-width: 991px) {
  .content__ufo .content__main__banner__ufo {
    margin-top: 60px;
  }
  .ufo {
    .slide {
      padding: 0.5rem 0 4.5rem 0;
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ufo/banners/bg-mobile.webp') !important;
      height: calc(100vh - 130px);
      height: calc(100svh - 130px);
      min-height: 660px;
    }
  }

  .content__back__nav {
    left: 5%;
    a {
      font-size: 18px;
    }
  }
  .modo__preparacion__noodles.ufo__preparacion .pasos {
    & > div {
      max-width: 250px;
      left: 0px;
    }
  }
  .slider__productos {
    .owl-dots button:not(.active) {
      display: none;
    }
    .owl-nav {
    }
  }
}
