@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
.content__nosotros {
  position: relative;
  .content__product__products {
    height: 70vh;
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    position: relative;
    background-size: cover;
    background-color: $rojoBase;
    background-position: center;
    background-repeat: no-repeat;
    &.noodles {
      overflow: hidden;
      background-position: top;
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-cn-mobile.jpg');
      .data__product {
        .content__img__product {
          position: relative;
          transform: translateX(40%);
          transition: all 1s;
          #main__img__product {
            position: relative;
            z-index: 2;
          }
        }
        .content__text__product {
          opacity: 0;
          transition: all 1s;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-name: slideOutRight;
        }
      }
      &.active .data__product {
        .content__img__product {
          position: relative;
          transform: translateX(0);
        }
        .content__text__product {
          animation-delay: .3s;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-name: fadeInRight;
        }
      }
    }
    &.ufo {
      overflow: hidden;
      background-position: top;
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-ufo-mobile.jpg');
      .data__product {
        .content__img__product {
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
          transition: all 1s;
          transform: translateX(30%);
          #main__img__product__ufo {
            z-index: 3;
            position: relative;
          }
          #logopassxpress {
            max-width: 300px;
          }
        }
        .content__text__product {
          opacity: 0;
          transition: all 1s;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-name: slideOutRight;
        }
      }
      &.active .data__product {
        .content__img__product {
          position: relative;
          transform: translateX(0);
        }
        .content__text__product {
          animation-delay: .3s;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-name: fadeInRight;
        }
      }
    }
    &.maxi {
      overflow: hidden;
      background-position: top;
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-maxi-mobile.jpg');
      .data__product {
        .content__img__product {
          position: relative;
          transform: translateX(30%);
          transition: all 1s;
          #main__img__product__maxi {
            position: relative;
            z-index: 4;
            display: block;
            margin: 0 auto;
          }
        }
        .content__text__product {
          opacity: 0;
          transition: all 1s;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-name: slideOutRight;
        }
      }
      &.active .data__product {
        .content__img__product {
          position: relative;
          transform: translateX(0);
        }
        .content__text__product {
          animation-delay: .3s;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-name: fadeInRight;
        }
      }
    }
    &.ramen {
      overflow: hidden;
      background-position: top;
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-top-mobile.jpg');
      .data__product .content__img__product {
        position: relative;
        transform: translateX(30%);
        transition: all 1s;
        #main__img__product__ramen {
          margin: 0 auto;
          display: block;
          position: relative;
          z-index: 3;
        }
      }
      .content__text__product {
        opacity: 0;
        transition: all 1s;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: slideOutRight;
      }
      &.active .data__product {
        .content__img__product {
          position: relative;
          transform: translateX(0);
        }
        .content__text__product {
          animation-delay: .3s;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-name: fadeInRight;
        }
      }
    }
    .data__product {
      width: 80%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .content__img__product {
        width: 60%;
        position: relative;
        img{
          width: auto;
          height: auto;
          max-height: 80%;
          max-width: 80%;
          display: block;
        }
        #main__img__product {
          margin: 0 auto;
        }
        #main__img__product__ufo {
          margin: 0 auto;
        }
        #main__img__product__maxi {
          margin: 0 auto;
        }
        #main__img__product__ramen {
          margin: 0 auto;
        }
      }
      .content__text__product {
        width: 40%;
        color: $color-blanco;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .name__product {
          margin: 0;
          font-size: 70px;
          font-family: 'CoreSansMSC87CnHeavy';
          line-height: 1;
          text-transform: uppercase;
          span img {
            margin: 0 0 20px 20px;
          }
        }
        .type__product {
          font-size: 22px;
          font-weight: 600;
        }
        .des__product {
          width: 75%;
          margin: 20px 0 30px;
          font-size: 18px;
          font-weight: 200;
        }
        .tipo__pasta {
          img {
            width: 60px;
            margin: 10px 0;
          }
          p {
            margin: 0;
            font-family: 'AmsiProCond-Black';
            text-transform: uppercase;
          }
        }
        .button_su {
          width: 40%;
          border-radius: 50px;
          margin-bottom: 30px;
          display: inline-block;
          position: relative;
          overflow: hidden;
        }
        .su_button_circle {
          width: 0px;
          height: 0px;
          border-radius: 1000px;
          margin-left: 0px;
          margin-top: 0px;
          pointer-events: none;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $rojoBase;
        }
        .button_su_inner {
          width: 100%;
          padding: 16px;
          border: 2px solid $rojoBase;
          border-radius: 50px;
          color: $color-blanco;
          font-size: 18px;
          font-weight: normal;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          display: inline-block;
          z-index: 100000;
          transition-duration: .4s;
          background: $rojoBase;
        }
        .button_text_container {
          position: relative;
          z-index: 10;
        }
        .explode-circle {
          animation: explode 0.5s forwards;
        }
        .desplode-circle {
          animation: desplode 0.5s forwards;
        }
      }
    }
  }
}

// ==========================
// media queries
// ==========================

@media (max-width: 552px) {
  .content__nosotros .content__product__products {
    height: auto;
    min-height: calc(100vh - 60px);
    padding-bottom: 50px;
    &.ufo .data__product {
      .content__img__product #logopassxpress {
        max-width: 180px;
        margin-top: 10px;
      }
      .content__text__product {
        .tipo__pasta {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 30px;
            margin-bottom: 0;
          }
          p {
            margin-left: 10px;
          }
        }
        .des__product {
          margin-top: 5px;
        }
      }
    }
    &.maxi .data__product .content__text__product .no__mobile {
      display: none;
    }
    .data__product {
      width: 95%;
      justify-content: center;
      .content__img__product {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        #main__img__product {
          min-width: auto;
          max-width: 200px;
        }
        #main__img__product__ufo {
          min-width: auto;
          max-width: 250px;
        }
        #main__img__product__maxi {
          min-width: auto;
          max-width: 250px;
        }
        #main__img__product__ramen {
          min-width: auto;
          max-width: 250px;
        }
      }
      .content__text__product {
        .name__product {
          font-size: 40px;
          span img {
            width: 45px;
            margin: 0 0 10px 10px;
          }
        }
        .des__product {
          width: 100%;
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 1.2;
        }
        .button_su {
          width: 80%;
          margin: 0 auto;
        }
        .button_su_inner {
          padding: 18px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .content__nosotros .content__product__products {
    background-position: 15%;
    &.noodles {
      background-position: top;
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-cn.jpg');
      .data__product .content__img__product {
        transform: translateY(90%);
        transition-duration: 1.5s;
        transition-property: transform;
      }
      &.active .data__product .content__text__product {
        animation-duration: 0;
      }
    }
    &.ufo {
      background-position: top;
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-ufo.jpg');
      .data__product .content__img__product {
        transform: translateY(90%);
        transition-duration: 1.5s;
        transition-property: transform;
      }
      &.active .data__product .content__text__product {
        animation-duration: 0;
      }
    }
    &.maxi {
      background-position: top;
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-maxi.jpg');
      .data__product .content__img__product {
        transform: translateY(90%);
        transition-duration: 1.5s;
        transition-property: transform;
      }
    }
    &.ramen {
      background-position: top;
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-top.jpg');
      .data__product .content__img__product {
        transform: translateY(50%);
        transition-duration: 1.5s;
        transition-property: transform;
      }
    }
    .data__product {
      width: 95%;
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;
      background-size: contain;
      .content__img__product {
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }
      .content__text__product {
        width: 80%;
        margin: 0 auto;
        .name__product {
          font-size: 50px;
        }
        .type__product {
          font-size: 18px;
        }
        .des__product {
          width: 100%;
        }
        .button_su {
          width: 30%;
        }
        .button_su_inner {
          padding: 18px;
        }
      }
    }
  }
}




@media (min-width: 992px) {
  .content__nosotros .content__product__products {
    background-position: 15%;
    &.noodles {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-cn.jpg');
    }
    &.ufo {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-ufo.jpg');
    }
    &.maxi {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-maxi.jpg');
    }
    &.ramen {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/nissin-productos-top.jpg');
    }
  }
}
