// ==========================
// Header
// ==========================

.bounceIn__product__menu {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounceIn__product__menu;
}

@keyframes bounceIn__product__menu {
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes moveX {
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: -370px;
  }
}

header {
  width: 100%;
  height: 80px;
  box-shadow: 0 5px 9px 0 rgba(75, 18, 18, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: transparent;
  background-color: #d9282e;
  .content__header {
    width: 95%;
    height: 80px;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    #iconMenuMobile {
      display: none;
    }
    .content__logo img {
      width: 110px;
      position: relative;
      z-index: 99;
    }
    .content__menu ul {
      padding: 0;
      margin: 0;
      display: flex;
      list-style: none;
      li {
        margin: 0 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        .noodleHover {
          width: 100%;
          height: 20px;
          display: none;
          position: absolute;
          bottom: 12px;
          animation: moveX 3s linear infinite;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url('../images/assets/noodle_sprite_nissi_2.png');
        }
        .content__link {
          height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          transition-duration: 0.2s;
          transition-property: transform;
        }
        a {
          color: $color-blanco;
          font-size: 24px;
          font-family: 'CoreSansMSC87CnHeavy';
          text-decoration: none;
        }
        .content__link div {
          display: flex;
          align-items: center;
          .content__arrow__down {
            margin-left: 0px;
            padding: 0 10px;
            cursor: pointer;
            display: inline-block;
            .arrowDown {
              width: 12px;
              height: 12px;
              border-left: 2px solid $color-blanco;
              border-bottom: 2px solid $color-blanco;
              display: inline-block;
              transform: rotate(-45deg);
              transform-origin: center 60%;
              transition-duration: 0.3s;
              transition-property: transform;
              background: transparent;
            }
          }
        }
        @media (min-width: 992px) {
          &.openSubmenu,
          &.active {
            .content__link {
              transform: rotate(-15deg);
              .noodleHover {
                display: block;
              }
            }
          }
        }
        @media (min-width: 992px) and (hover: hover) {
          &:hover .content__link {
            transform: rotate(-15deg);
            .noodleHover {
              display: block;
            }
          }
        }
        @media (hover: hover) {
          &:hover .content__link div .content__arrow__down .arrowDown {
            transform: rotate(135deg);
          }
        }
        &.openSubmenu .content__link div .content__arrow__down .arrowDown {
          transform: rotate(135deg);
        }
      }
      @media screen and (min-width: 992px) {
        #menuNissinMundo,
        .content__products__mobile {
          display: none !important;
        }
      }
    }
    .content__products__menu {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 80px;
      left: 0;
      visibility: hidden;
      transition-delay: 0.35s;
      transition-property: visibility;
      .product__menu {
        width: 25%;
        padding: 70px 0 50px;
        box-shadow: 10px 1px 10px rgba(0, 0, 0, 0.2);
        text-decoration: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        opacity: 0;
        visibility: hidden;
        transition-duration: 0.3s;
        transition-property: opacity, visibility;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        &[data-id] {
          background: no-repeat center center;
          background-size: contain;
          img {
            display:  block;
            width: auto;
            height: auto;
            margin: auto auto;
            max-width: 80%;
            max-height: 80%;
          }
        }
        &[data-id='secondary-menu-noodles'] {
          background-color: #da282f;
          background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/menu/nissin-cupnoodles-menu-pattern.svg);
        }
        &[data-id='secondary-menu-ufo'] {
          background-color: #d4a753;
          background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/menu/nissin-ufo-menu-pattern.svg);
        }
        &[data-id='secondary-menu-maxi'] {
          background-color: #71ad62;
          background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/menu/nissin-maxisopa-menu-pattern.svg);
        }
        &[data-id='secondary-menu-ramen'] {
          background-color: #f07034;
          background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/menu/nissin-topramen-menu-pattern.svg);
        }
        &[data-id='secondary-menu-recipes'] {
          background-color: #da282f;
          background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/menu/nissin-cupnoodles-menu-pattern.svg);
        }
        &[href*='manualsos'] img {
          width: 95%;
          max-width: 350px;
        }
        p {
          margin-bottom: 0;
          color: #fff;
          font-size: 30px;
          font-family: 'CoreSansMSC87CnHeavy';
          text-align: center;
        }
      }
      &.active {
        z-index: 3;
        visibility: visible;
        transition-duration: 0.25s;
        .product__menu {
          opacity: 1;
          visibility: visible;
          &:nth-child(2) {
            transition-duration: 0.5s;
          }
          &:nth-child(3) {
            transition-duration: 0.65s;
          }
          &:nth-child(4) {
            transition-duration: 0.8s;
          }
          &:hover {
            img,
            p {
              animation-duration: 1s;
              animation-fill-mode: both;
              animation-name: bounceIn__product__menu;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  header {
    .content__header {
      #iconMenuMobile {
        width: 42px;
        height: 35px;
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        cursor: pointer;
        z-index: 99;
        transform: translateY(-50%);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../images/assets/icons/menu.svg');
        &.active {
          background-image: url('../images/assets/icons/cancel_modal.svg');
        }
      }
      .content__menu {
        width: 100%;
        height: calc(100vh - 80px);
        margin-top: 80px;
        position: absolute;
        left: 0;
        top: 0;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-1.5%);
        transition-duration: 0.5s;
        transition-property: opacity, visibility, transform;
        background: rgba(217, 40, 46, 1);
        &.active {
          opacity: 1;
          overflow-y: scroll;
          visibility: visible;
          transform: translateY(0%);
        }
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          li {
            width: 100%;
            padding: 10px 20px;
            border-top: 1px solid rgba(#ddd, 0.5);
            margin: 0;
            align-items: flex-start;
            .content__link {
              height: auto;
            }
            a {
              font-size: 25px;
            }
            &:last-child {
              border-bottom: 1px solid rgba(#ddd, 0.5);
            }
            &.link_submenu {
              .content__link {
                width: 100%;
                a {
                  width: 100%;
                }
                div {
                  justify-content: space-between;
                }
              }
              &.openSubmenu,
              &.active {
                a {
                  color: #f1fc46;
                }
                .content__link div .content__arrow__down .arrowDown {
                  border-left: 2px solid #f1fc46;
                  border-bottom: 2px solid #f1fc46;
                }
              }
            }
          }
          #menuNissinMundo {
            display: flex !important;
          }
          .content__products__mobile {
            width: 100%;
            padding: 0;
            display: none;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            & > a {
              width: 50%;
              height: 250px;
              padding: 2%;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              background: no-repeat center center;
              background-size: cover;
              p {
                margin-bottom: 0;
                margin-top: 0.5rem;
              }
              img {
                height: 12rem;
                width: auto;
              }
              &[data-id] {
                background: no-repeat center center;
                background-size: contain;
              }
              &[data-id='secondary-menu-noodles'] {
                background-color: #da282f;
                background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/menu/nissin-cupnoodles-menu-pattern.svg);
              }
              &[data-id='secondary-menu-ufo'] {
                background-color: #d4a753;
                background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/menu/nissin-ufo-menu-pattern.svg);
              }
              &[data-id='secondary-menu-maxi'] {
                background-color: #71ad62;
                background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/menu/nissin-maxisopa-menu-pattern.svg);
              }
              &[data-id='secondary-menu-ramen'] {
                background-color: #f07034;
                background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/menu/nissin-topramen-menu-pattern.svg);
              }
              &[href*='manualsos'] img {
                max-width: 160px;
              }
            }
          }
        }
      }
      .content__products__menu {
        display: none;
      }
    }
  }
}
@media (max-width: 552px) {
  header {
    height: 60px;
    .content__header {
      height: 60px;
      .content__logo img {
        width: 70px;
      }
      .content__menu {
        height: calc(100vh - 60px);
        margin-top: 60px;
        ul .content__products__mobile > a {
          img {
            height: 10rem;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}
