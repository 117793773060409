footer {
  border-bottom: 1px solid $rojoBase2;
  background: $rojoBase;
  .content__footer {
    width: 95%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: calc(100% - 180px) center;
    background-image: url('../images/assets/bg_noodles_footer.svg');
    p {
      margin-bottom: 40px;
      color: $color-blanco;
      font-size: 12px;
      text-transform: uppercase;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .left__side {
      display: flex;
      padding: 20px 0;
      ul {
        margin-left: 100px;
        display: flex;
        align-self: center;
        li {
          margin: 0 10px;
          a {
            color: $color-blanco;
            font-size: 16px;
            font-family: 'AmsiProCond-Black';
            text-transform: uppercase;
            text-decoration: none;
            &:hover {
              color: #f1fc46;
            }
          }
        }
      }
    }
    .right__side {
      display: flex;
      align-items: center;
      padding: 20px 0;
      ul {
        display: flex;
        align-self: center;
        li {
          margin: 0 5px;
          a img {
            width: 40px;
            height: auto;
          }
        }
      }
    }
  }
}

.content__footer__2 {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  background: $rojoBase;
  a {
    margin: 0 10px;
    color: $color-blanco;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-decoration: none;
    &:hover {
      color: #f1fc46;
    }
  }
  p {
    margin: 0 10px;
    color: $color-blanco;
    font-size: 10px;
    font-weight: 600;
  }
}
@media (max-width: 991px) {
  footer {
    border-bottom: none;
    .content__footer {
      align-items: center;
      flex-direction: column;
      background-image: none;
      p {
        margin-bottom: 40px;
        color: $color-blanco;
        font-size: 12px;
        text-transform: uppercase;
      }
      ul {
        flex-direction: column;
      }
      .left__side {
        flex-direction: column;
        ul {
          margin-top: 20px;
          margin-left: 0;
          align-items: center;
          li {
            margin: 10px 0;
          }
        }
      }
      .right__side {
        padding-top: 0;
        ul {
          flex-direction: row;
        }
      }
    }
  }
  .content__footer__2 {
    padding: 10px 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #c6242a;
    a {
      margin: 15px 0px;
      font-size: 14px;
      text-align: center;
    }
    p {
      margin: 15px 0px;
      font-size: 14px;
      text-align: center;
    }
  }
}
