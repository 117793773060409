.ramen-hero {
  .slide {
    background-size: cover;
    height: auto;
    aspect-ratio: 2 / 1;
    .content__slide {
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .slide {
      aspect-ratio: 0.6088;
    }
  }
}
.ramen {
  --ramen-camaron-bg: #e40063;
  --ramen-pollo-bg: #D94A14;
  .slide {
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    display: flex;
    .content__slide {
      padding: 0;
    }
    &[data-id='ramen-camaron'] {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/product-slider/bg-desktop-camaron.png');
    }
    &[data-id='ramen-pollo'] {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/product-slider/bg-desktop-pollo.png');
    }
    &.reversed[data-id='ramen-camaron'] {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/product-slider/bg-desktop-pollo.png');
    }
    &.reversed[data-id='ramen-pollo'] {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/product-slider/bg-desktop-camaron.png');
    }
  }
  .owl-dots {
    background-color: var(--ramen-camaron-bg);
    &[data-id='0'] {
      background-color: var(--ramen-camaron-bg);
    }
    &[data-id='1'] {
      background-color: var(--ramen-pollo-bg);
    }
    .owl-dot {
      &:nth-child(1) span {
        &::after {
          background-color: var(--ramen-camaron-bg);
        }
        &::before {
          content: 'Camarón';
        }
      }
      &:nth-child(2) span {
        &::after {
          background-color: var(--ramen-pollo-bg);
        }
        &::before {
          content: 'Pollo';
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .content__ramen .content__main__banner__ramen {
    margin-top: 60px;
  }
  .ramen {
    .slide {
      background-size: cover;
      height: calc(100vh - 216px);
      height: calc(100svh - 216px);
      min-height: 655px;
      // padding: 0.5rem 0 4.5rem 0;
      padding: 0 0 3rem;
      .content__slide {
        width: calc(100% - 2rem);
      }
    }
  }
}
@media (min-width: 992px) {
  .content__ramen .content__main__banner__ramen {
    margin-top: 80px;
  }
  .ramen {
    .slide {
      background-size: cover;
      height: calc(100vh - 216px);
      height: calc(100svh - 216px);
      min-height: 520px;
      .content__slide {
        padding: 0;
        width: calc(100% - 14rem);
      }
      .content__slide .right__side .iconos__detalles .detalle {
        margin-right: 20px;
      }
    }
  }
}

.asian {
  --ramen-mariscos-bg: #5D8E95;
  --ramen-ajonjoli-bg: #A15C22;
  .slide {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    // position: relative;
    .content__slide {
      padding: 0;
    }
    &[data-id='asian-mariscos'] {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/product-slider/asian-bg-mariscos-desktop.jpg');
    }
    &[data-id='asian-ajonjoli'] {
      background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/product-slider/asian-bg-ajonjoli-desktop.jpg');
    }
    &::after{
      background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/product-slider/asia-linea.dorada.jpg);
      background-position: center center;
      background-size: 100% 100%;
      bottom: 0;
      content: '';
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
  .owl-dots {
    background-color: var(--ramen-mariscos-bg);
    &[data-id="0"] {
      background-color: var(--ramen-mariscos-bg);
    }
    &[data-id="1"] {
      background-color: var(--ramen-ajonjoli-bg);
    }
    .owl-dot {
      &:nth-child(1) span {
        &::after {
          background-color: var(--ramen-mariscos-bg);
        }
        &::before {
          content: 'Mariscos';
        }
      }
      &:nth-child(2) span {
        &::after {
          background-color: var(--ramen-ajonjoli-bg);
        }
        &::before {
          content: 'Ajonjolí';
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .content__asian .content__main__banner__asian {
    margin-top: 60px;
  }
  .asian {
    .slide {
      background-size: cover;
      height: calc(100vh - 216px);
      height: calc(100svh - 216px);
      min-height: 790px;
      padding: 1rem 0 3.4rem;
      .content__slide {
        width: calc(100% - 2rem);
      }
    }
  }
}
@media (min-width: 992px) {
  .content__asian .content__main__banner__asian {
    margin-top: 80px;
  }
  .asian {
    .slide {
      background-size: cover;
      // height: calc(100vh - 150px);
      // height: calc(100svh - 150px);
      height: calc(100vh - 216px);
      height: calc(100svh - 216px);
      min-height: 520px;
      .content__slide {
        padding: 0;
        width: calc(100% - 14rem);
        h2 {
          font-size: 2.2rem;
        }
      }
      .content__slide .right__side .iconos__detalles .detalle {
        margin-right: 20px;
      }
      .content__slide .left__side  {
        padding: 3vh 0;
      }
      /*
      &[data-id='asian-mariscos'] {
        background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/product-slider/asian-bg-desktop.jpg');
      }
      &[data-id='asian-ajonjoli'] {
        background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/products/ramen/product-slider/asian-bg-desktop.jpg');
      }
      */
    }
  }
}
