.button_su {
  width: auto;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  .su_button_circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  @media (max-width: 991px) {
    &.mobile-center-bottom{
      position: absolute;
      left: 50%;
      bottom: 2rem;
      transform: translateX(-50%);
    }
    &.mobile-left-center{
      position: relative;
      left: 0;
    }
  }
  @media (min-width: 992px) {
    &.desktop-center-bottom{
      position: absolute;
      left: 50%;
      bottom: 2rem;
      transform: translateX(-50%);
    }
  }
}
.button_su_inner {
  width: 100%;
  padding: 15px 22px;
  border-radius: 50px;
  color: $color-blanco;
  font-size: 18px;
  font-family: 'AmsiProCond-Black';
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  z-index: 100000;
  transition-duration: 0.4s;
  transition-property: color;
  background-color: $rojoBase;
  &:hover {
    color: #fff;
  }
}

.button_text_container {
  position: relative;
  z-index: 10;
}
.explode-circle {
  animation: explode 0.5s forwards;
}

// ################################
.content__main__banner {
  margin-top: 80px;
  position: relative;
  .owl-dots {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10%;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    button {
      width: 60px;
      height: 60px;
      border: none;
      border-radius: 50%;
      margin: 10px 0;
      cursor: pointer;
      outline: 0;
      transition-duration: 0.5s;
      transition-property: background;
      background-image: url('../images/assets/icons/bullet-off.svg');
      span {
        display: none !important;
      }
      &.active {
        background-image: url('../images/assets/icons/bullet-on.svg');
      }
      @media (hover: hover) {
        &:hover {
          background-image: url('../images/assets/icons/bullet-on.svg');
        }
      }
    }
  }
  .content__main__carousel {
    .item__main__slider__home {
      width: 100%;
      height: auto;
      aspect-ratio: 2;
      display: flex;
      align-items: center;
      background-size: cover;
      background-position: center;
      .content__slide {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
        p {
          width: 100%;
          color: #ffffff;
          font-size: 30px;
          font-family: 'CoreSansMSC87CnHeavy';
          line-height: 39px;
          letter-spacing: 0;
          span {
            margin-left: 0;
            color: #fae04b;
          }
          small {
            font-size: 16px;
            line-height: 1;
          }
        }
        .title__slide {
          color: #fff;
          font-size: 54px;
          font-family: 'CoreSansMSC87CnHeavy';
          margin: 0;
          line-height: 1;
          display: flex;
          align-items: center;
          span {
            margin-left: 15px;
            img {
              width: 95px;
            }
          }
        }
        .btn-float {
          width: 36%;
          max-width: 350px;
          margin: auto;
          display: block;
          position: absolute;
          left: 11%;
          right: 0;
          bottom: 10.35%;
          img {
            height: auto;
          }
        }
      }
      &[data-id='ufo'] .content__slide {
        margin-top: 135px;
        p {
          max-width: 370px;
        }
      }
    }
  }
}
// ==========================
// productos home
// ==========================

.content__products__home {
  display: flex;
  flex-wrap: wrap;
  .product__home {
    width: 50%;
    padding: 1rem;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    .content__product__img {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform-style: preserve-3d;
      background: rgba(0, 0, 0, 0.05);
      img {
        width: auto;
        height: 100%;
        margin: auto;
        transition: transform 0.5s;
      }
    }
    .content__text__product {
      width: calc(100% - 420px);
      transform-style: preserve-3d;
      p,
      small {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 15%;
        color: $color-blanco;
      }
      p {
        font-size: 45px;
        font-family: 'CoreSansMSC87CnHeavy';
        text-transform: uppercase;
        line-height: 1;
      }
      small {
        font-size: 30px;
        font-family: 'AmsiProCond-Black';
        display: none;
      }
      .noodleHover {
        width: 180px;
        height: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-bottom: 10px;
        display: none;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../images/assets/noodle_sprite_nissin.png');
        &.small {
          width: 180px;
        }
      }
    }
    &:hover {
      .content__product__img img {
        transform: rotate(-20deg) scale(1.2);
      }
      .content__text__product {
        p {
          animation-duration: 0.5s;
          animation-fill-mode: both;
          animation-name: bounceInUp;
        }
        small {
          display: block;
        }
        .noodleHover {
          display: block;
        }
      }
    }
    &[data-id] {
      background: white no-repeat center center;
      background-size: cover;
    }
    &[data-id='home-products-noodles'] {
      background-color: #da282f;
      background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/home/nissin-cupnoodles-pattern.png);
    }
    &[data-id='home-products-ufo'] {
      background-color: #d4a753;
      background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/home/nissin-ufo-pattern.png);
    }
    &[data-id='home-products-maxi'] {
      background-color: #71ad62;
      background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/home/nissin-maxisopa-pattern.png);
    }
    &[data-id='home-products-ramen'] {
      background-color: #f07034;
      background-image: url(https://nissinmx.s3.amazonaws.com/resources/images/home/nissin-topramen-pattern.png);
    }
  }
}
// ==========================
// conoce mas home
// ==========================
.content__conoce__mas {
  width: 90%;
  max-width: 1200px;
  padding: 70px 0 120px;
  margin: 0 auto;
  background-size: contain;
  background-position: 10% 10%;
  background-repeat: no-repeat;
  background-image: url('../images/home/conoce/bg_noodles.png');
  .content__title__nissin {
    padding: 10px 0;
    text-align: center;
    position: relative;
    top: 50px;
    h2 {
      margin: 0;
      color: rgba($color-negro, 0.7);
      font-size: 80px;
      font-family: 'CoreSansMSC87CnHeavy';
      text-transform: uppercase;
    }
    p {
      margin: 0;
      color: rgba($color-negro, 0.7);
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .conoce__mas__images {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    .image__mas {
      width: 24%;
      height: 550px;
      text-decoration: none;
      display: flex;
      position: relative;
      overflow: hidden;
      & > p {
        margin: 0;
        color: $color-blanco;
        font-size: 30px;
        font-family: 'CoreSansMSC87CnHeavy';
        line-height: 1;
        position: absolute;
        top: 5%;
        left: 5%;
        z-index: 5;
        span {
          width: 100px;
          height: 18px;
          margin-top: 10px;
          display: block;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url('../images/assets//noodle-horizontal-chico.png');
        }
      }
      .image__content {
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition-duration: 0.2s;
        transition-property: background;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .content__back {
        width: 100%;
        height: 100%;
        padding: 0 10px;
        margin-top: 120px;
        pointer-events: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition-duration: 0.2s;
        transition-property: opacity;
        p {
          margin-top: 5px;
          font-size: 18px;
          font-weight: 200;
          align-self: flex-start;
          &.red__text {
            margin-top: 10px;
            color: $rojoBase;
            font-size: 25px;
            font-family: 'AmsiProCond-Black';
            text-transform: uppercase;
            line-height: 1;
          }
        }
      }

      &.nosotros .image__content {
        background-image: url('../images/home/conoce/nissin-historia.png');
      }
      &.social {
        margin-top: 70px;
        .image__content {
          background-image: url('../images/home/conoce/nissin-conoce-resp.png');
        }
      }
      &.mundo .image__content {
        background-image: url('../images/home/conoce/nissin-conoce-mundo.png');
      }
      &.historia {
        margin-top: 70px;
        .image__content {
          background-image: url('../images/home/conoce/nissin-conoce-museo.png');
        }
      }
    }
  }
}
@media (hover: none) {
  .content__conoce__mas .conoce__mas__images .image__mas .image__content {
    pointer-events: visible;
  }
}
// ==========================
// social media
// ==========================
.content__social__home {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  background-size: contain;
  background-image: url('../images/home/social/nissin-redes-bg.png');
  .content__title__nissin {
    padding: 10px 0;
    color: $color-blanco;
    text-align: center;
    h2 {
      margin: 0;
      font-size: 80px;
      font-family: 'CoreSansMSC87CnHeavy';
      text-transform: uppercase;
    }
    p {
      margin: 0;
      font-size: 22px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .icons__social {
    padding: 30px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 10%;
    top: 5%;
    a {
      width: 60px;
      height: 60px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../images/home/social/bg-btn-reposo.png');
      img {
        width: 25px;
        transition: transform 0.5s;
        &:hover {
          transform: scale(1.2);
        }
      }
      &:hover {
        background-image: url('../images/home/social/bg-btn-off.png');
      }
    }
  }
  .content__grid__social {
    margin-top: 25px;
    position: relative;
    [class*='grid--layout-'] {
      width: 92.5vw;
      height: 67vw;
      margin: auto;
      align-content: normal;
      grid-row-gap: 1.25vw;
      grid-column-gap: 1.25vw;
      grid-auto-rows: calc(calc(92.5vw / 30) - 1.25vw);
      grid-auto-columns: calc(calc(92.5vw / 30) - 1.25vw);
      top: 0;
      left: 0;
      right: 0;
      &.grid--layout-1 {
        position: relative;
      }
    }
  }
  .content__imgs__from__social,
  .buttons__slider__mobile,
  .text__slider__mobile {
    display: none;
  }
}
// ==========================
// imagenes desde redes
// ==========================
.grid__item {
  button {
    width: 25px;
    height: 25px;
    padding: 0;
    border: none;
    outline: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    background: transparent;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../images/assets/icons/play-button.svg');
    &.active {
      background-image: url('../images/assets/icons/pause-button.svg');
    }
  }
  &:hover button {
    opacity: 1;
  }
  .img__social {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    img {
      width: 100%;
      height: auto;
    }
  }
}

// ==========================
// media queries
// ==========================
@media (max-width: 1200px) {
  .content__main__banner
    .content__main__carousel
    .item__main__slider__home
    .content__slide
    .btn-float {
    max-width: 260px;
  }
  .content__products__home .product__home {
    padding: 5% 2%;
    justify-content: space-evenly;
    .content__product__img {
      width: 200px;
      height: 210px;
    }
    .content__text__product {
      p {
        font-size: 30px;
      }
      small {
        font-size: 18px;
      }
      .noodleHover.small {
        width: 120px;
        height: 10px;
      }
    }
  }
}

@media (min-width: 992px) {
  .content__conoce__mas .conoce__mas__images .image__mas:hover {
    .image__content {
      background-image: url('../images/home/conoce/nissin-nosotros-bg.png');
    }
    p {
      color: #535353;
      span {
        background-image: url('../images/assets/noodle-horizontal-chico-beige.svg');
      }
    }
    .content__back {
      pointer-events: auto;
      opacity: 1;
      p {
        color: #6a6a6a;
      }
    }
  }
}
@media (max-width: 991px) {
  .content__main__banner {
    .owl-dots {
      right: 2%;
      button {
        width: 35px;
        height: 35px;
        margin: 6px 0;
        background-size: contain;
        background-position: center;
      }
    }
    .content__main__carousel .item__main__slider__home {
      // aspect-ratio: 0.5625;
      align-items: flex-end;
      aspect-ratio: 0.625;
      // aspect-ratio: 0.609;
      background-position: center center;
      background-size: cover;
      height: auto;
      padding-bottom: 0;
      &[data-id='banner-ufo'] {
        background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/home/main-slider/ufo/ufo-mobile.webp') !important;
      }
      &[data-id='banner-ta-potente'] {
        background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/home/main-slider/ta-potente/ta-potente-mobile.png') !important;
      }
      &[data-id='banner-ramen'] {
        background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/home/main-slider/ramen/banner-ramen-mobile.png') !important;
      }
      &[data-id='banner-asian'] {
        background-image: url('https://nissinmx.s3.amazonaws.com/resources/images/home/main-slider/asian/banner-asian-mobile.jpg') !important;
      }
      .content__slide {
        .title__slide {
          font-size: 30px;
          span {
            display: none;
          }
          &:nth-child(3) {
            margin-bottom: 10px;
          }
        }
        .text__slide {
          margin-bottom: 15px;
          font-size: 22px;
          span {
            color: #d9282e;
          }
        }
        .btn-float {
          min-width: 150px;
          top: 60vw;
          bottom: auto;
          left: 0%;
        }
      }
    }
  }
  // =============================
  // produstos del home
  // =============================
  .content__products__home {
    margin-top: 0;
    display: block;
    .product__home {
      width: 100%;
      padding: 20px;
      .content__text__product {
        width: 35%;
        small {
          font-size: 16px;
          display: none;
        }
        span {
          margin-left: 0;
        }
      }
    }
  }
  // =============================
  // conoce mas home
  // =============================
  .content__conoce__mas {
    width: 100%;
    background-image: none;
    .content__title__nissin {
      top: 0;
      h2 {
        font-size: 75px;
      }
      p {
        font-size: 30px;
      }
    }
    .conoce__mas__images {
      margin-top: 30px;
      flex-direction: column;
      .image__mas {
        width: 50%;
        height: 450px;
        margin: 0 auto 20px;
        p {
          font-size: 30px;
        }
        &.social,
        &.historia {
          margin-top: 0;
        }
      }
    }
  }
  // =============================
  // social home
  // =============================
  .content__social__home {
    min-height: auto;
    padding-bottom: 100px;
    .content__title__nissin {
      h2 {
        font-size: 75px;
      }
      p {
        font-size: 30px;
      }
    }
    .icons__social {
      padding: 20px 0;
      position: relative;
      right: inherit;
      top: inherit;
      img {
        width: 40px;
        margin: 0 20px;
      }
    }
    .content__imgs__from__social {
      width: 80%;
      max-width: 500px;
      margin: 0 auto;
      display: block;
      button {
        width: 50px;
        height: 50px;
        border: none;
        cursor: pointer;
        padding: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        outline: 0;
        transform: translate(-50%, -50%);
        background: transparent;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../images/assets/icons/play-button.svg');
        &.active {
          background-image: url('../images/assets/icons/pause-button.svg');
        }
      }
    }
    .buttons__slider__mobile {
      width: 80%;
      max-width: 500px;
      padding: 10px 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      button {
        width: 70px;
        height: 70px;
        padding: 10px;
        border: none;
        outline: 0;
        background-size: cover;
        background-image: url('../images/home/social/bg-nav-grid.svg');
        img {
          width: 30px;
        }
        &:nth-child(2) img {
          transform: rotate(180deg);
        }
      }
    }
    .content__grid__social {
      display: none;
    }
    .text__slider__mobile {
      width: 80%;
      max-width: 500px;
      margin: 20px auto 0;
      display: flex;
      p {
        margin: 0;
        color: #fff;
        font-size: 40px;
        font-family: 'AmsiProCond-Black';
        line-height: 1;
      }
    }
  }
}
@media (max-width: 768px) {
  .content__imgs__from__social {
    width: 80%;
  }
}
@media (max-width: 552px) {
  .content__main__banner {
    margin-top: 60px;
    background-color: black;
    .content__main__carousel .item__main__slider__home {
      height: auto;
      padding: 0;
      background-color: black;
      background-repeat: no-repeat;
      background-size: cover;
      .content__slide {
        padding: 0 30px 20px 0;
        margin-top: 50px !important;
        p {
          margin: 10px auto 20px;
        }
      }
    }
  }
  .content__products__home .product__home {
    justify-content: space-around;
    .content__product__img {
      width: 150px;
      height: 160px;
      img {
        height: 100%;
      }
    }
    .content__text__product p {
      font-size: 25px;
    }
    .content__text__product span.small,
    .content__text__product small {
      display: block;
    }
    .content__text__product .noodleHover{
      margin-top: 10px;
    }
    .content__text__product p,
    .content__text__product span,
    .content__text__product small {
      margin-left: 0!important;
    }
  }
  .content__conoce__mas {
    padding: 30px 0 60px;
    .conoce__mas__images .image__mas {
      width: 80%;
      height: 400px;
      p {
        font-size: 30px;
        display: block;
        opacity: 1;
      }
    }
  }
}
