.content__contacto {
  margin-top: 80px;
  .header__contact {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .content__form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .left__form {
        width: 50%;
        min-height: 650px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: contain;
        background-image: url('../images/assets/nissin-contacto-bg-red.png');
        .left__left__form {
          width: 35%;
          img {
            width: 100%;
            height: auto;
            max-width: 230px;
          }
        }
        .right__left__form {
          width: 65%;
          padding-right: 10px;
          padding-left: 10px;
          .title__contact {
            margin: 0 0 30px;
            color: $color-blanco;
            font-size: 60px;
            font-family: 'CoreSansMSC87CnHeavy';
            line-height: 1;
            text-transform: uppercase;
            animation-delay: .2s;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-name: bounceInLeft;
            span img {
              margin: 0 0 20px 0;
            }
          }
          .text__form {
            color: $color-blanco;
            font-size: 22px;
            font-weight: 200;
            line-height: 1.5;
            animation-delay: .2s;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-name: bounceInLeft;
          }
        }
      }
      .right__form {
        width: 50%;
        min-height: 650px;
        padding-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        animation-delay: .4s;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: bounceInRight;
        background-size: contain;
        background-position: center;
        background-image: url('../images/assets/nissin-contacto-bg-amarillo.png');
        .content__input {
          max-width: 500px;
          position: relative;
          img {
            position: absolute;
            right: 2%;
            top: 25%;
            z-index: 2;
            opacity: 0;
            &.active {
              opacity: 1;
            }
          }
          &#contentInputTextarea img {
            position: absolute;
            right: 2%;
            top: 5%;
            transform: translateY(-5%);
          }
        }
        .nice-select {
          max-width: 500px;
        }
        input,
        textarea {
          width: 100%;
          border: none;
          padding: 15px 10px;
          margin-bottom: 10px;
          color: #a69494;
          font-weight: 200;
          outline: 0;
          display: block;
          background-color: #fff;
          &:focus {
            border: 1px solid $rojoBase2;
          }
          &.error {
            border: 1px solid $rojoBase;
          }
        }
        .mensaje__error__form {
          margin-top: 0;
          margin-bottom: 5px;
          font-weight: 200;
          color: $rojoBase;
          display: none;
        }
        .content__submit__mensaje {
          max-width: 500px;
          display: flex;
          align-items: center;
          justify-content: center;
          .button_su {
            width: 40%;
            border-radius: 50px;
            margin-bottom: 30px;
            margin-top: 30px;
            display: inline-block;
            position: relative;
            overflow: hidden;
          }
          .su_button_circle {
            width: 0px;
            height: 0px;
            border-radius: 1000px;
            margin-left: 0px;
            margin-top: 0px;
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
          }
          .button_su_inner {
            width: 100%;
            padding: 12px;
            border: 2px solid #da282f;
            border-radius: 50px;
            color: $color-blanco;
            font-size: 18px;
            font-weight: normal;
            font-family: 'AmsiProCond-Black';
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            display: inline-block;
            z-index: 100000;
            transition: .4s;
            background-color: #da282f;
          }
          .button_text_container {
            position: relative;
            z-index: 10;
          }
          .explode-circle {
            animation: explode 0.5s forwards;
          }
          .desplode-circle {
            animation: desplode 0.5s forwards;
          }
          p {
            color: #fff;
            font-size: 12px;
            font-weight: 200;
            margin: 0;
            display: none;
          }
        }
      }
    }
  }
  .content__map .map {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .text__map {
      width: 50%;
      padding-left: 13%;
      box-shadow: 2px 0px 15px 0px rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      z-index: 2;
      p {
        color: #6a6a6a;
        font-size: 18px;
        text-align: left;
      }
      .title__text__map {
        margin: 0;
        color: #da282f;
        font-size: 60px;
        font-family: 'CoreSansMSC87CnHeavy';
        line-height: 1;
        text-align: left;
        text-transform: uppercase;
        span img {
          margin: 0 0 20px 0;
        }
      }
    }
    .map__contacto {
      width: 50%;
      min-height: 600px;
      background-size: cover;
      background-position: center;
      background-image: url('../images/nosotros-mundo/mapa.png');
    }
  }
}
::placeholder {
  color: #a69494;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #a69494;
}

::-ms-input-placeholder {
  color: #a69494;
}

@media (max-width: 1200px) {
  .content__contacto {
    .header__contact .content__form {
      .left__form .right__left__form {
        .title__contact {
          font-size: 50px;
          span img {
            width: 50px;
          }
        }
        .text__form {
          font-size: 18px;
        }
      }
      .right__form {
        padding-left: 0;
        .content__input, .nice-select, .mensaje__error__form {
          width: 95%;
          margin: 0 auto;
        }
        .nice-select {
          margin-bottom: 10px;
        }
        .mensaje__error__form {
          margin-bottom: 5px;
        }
      }
    }
    .content__map .map .text__map {
      padding-left: 5%;
    }
  }
}
@media (max-width: 991px) {
  .content__contacto {
    .header__contact .content__form {
      flex-direction: column;
      .left__form {
        width: 100%;
      }
      .right__form {
        width: 100%;
        .content__submit__mensaje {
          width: 100%;
          align-self: center;
        }
      }
    }
    .content__map .map {
      flex-direction: column;
      .map__contacto {
        width: 100%;
        min-height: 400px;
      }
      .text__map {
        width: 100%;
        padding: 100px 50px 100px 5%;
        .title__text__map {
          font-size: 40px;
          span img {
            width: 50px;
            margin: 0 0 10px 0;
          }
          .hide__mobile {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 552px) {
  .content__contacto {
    .header__contact .content__form {
      .left__form {
        min-height: auto;
        padding: 40px 0;
        .left__left__form {
          width: 20%;
        }
        .right__left__form {
          width: 80%;
          padding-left: 10%;
          .title__contact {
            margin-bottom: 10px;
            font-size: 35px;
            span img {
              margin-bottom: 10px;
            }
          }
          .text__form {
            margin-top: 0;
            font-size: 14px;
          }
        }
      }
      .right__form .content__submit__mensaje .button_su {
        width: 60%;
      }
    }
    .content__map .map .text__map {
      .title__text__map {
        font-size: 22px;
        span img {
          width: 40px;
          margin: 0 0 5px 0;
        }
        .hide__mobile {
          display: none;
        }
      }
      p {
        font-size: 16px;
      }
    }
  }
}

.bounceInLeft {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounceInLeft;
}
@keyframes bounceInLeft {
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInRight {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounceInRight;
}
@keyframes bounceInRight {
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
